import React, { useContext } from 'react';
import {
  List, ListItem, ListItemAvatar, ListItemText, Avatar, Button, Grid, CircularProgress, Tooltip,
} from '@mui/material/';
import GetAppIcon from '@mui/icons-material/GetApp';
import Alert from '@mui/material/Alert';
import UserContext from 'context/UserContext';
import moment from 'moment';
import { useStyles } from './FileList.jss';
import { IRow } from '../FileManagement/FileManagement.types';
import { IFileListProps } from './FileList.types';
import { downloadFile } from './FileList.api';

const FileList: React.FunctionComponent<IFileListProps> = (props) => {
  const classes = useStyles;
  const token = useContext(UserContext)?.token;
  const {
    sortDirection, rows, type, loading, error,
  } = props;

  const sortArr = (a:IRow, b:IRow):number => {
    const x = a.last;
    const y = b.last;
    if (sortDirection) {
      return x > y ? 1 : -1;
    }
    return x < y ? 1 : -1;
  };

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <br />
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>
    );
  }

  if (error && !loading) {
    const msg = `${error.name}: ${error.message}`;
    return (
      <Grid item xs={12}>
        <Alert sx={classes.alert} severity="error">{msg}</Alert>
      </Grid>
    );
  }

  const handleDownload = (path:string):void => {
    downloadFile(token, path);
  };

  const renderRows = ():JSX.Element[] | undefined => rows?.sort((a, b) => sortArr(a, b)).filter((row) => row.direction === type).map((row) => {
    const rowclass = row.type === 'report' ? classes.avatarred : classes.avatargreen;
    return (
      <ListItem key={row.name}>
        <Tooltip title={`Uploaded: ${moment(row.last).format('MM/DD/YYYY')}`} placement="left">
          <ListItemAvatar>
            <Avatar sx={rowclass}> </Avatar>
          </ListItemAvatar>
        </Tooltip>
        <ListItemText sx={classes.listitemtext} primary={row.name} />
        <Button onClick={() => handleDownload(row.path)}><GetAppIcon sx={classes.icon} /></Button>
      </ListItem>
    );
  });

  const renderList = ():JSX.Element => {
    if (rows) {
      return (
        <List sx={classes.root}>
          {renderRows()}
        </List>
      );
    }
    return <div />;
  };

  return (
    <Grid item xs={12}>
      {renderList()}
    </Grid>
  );
};

export default FileList;
