import React, {
  useState, useEffect, useContext,
} from 'react';
import moment, { Moment } from 'moment';
import {
  Button, ButtonGroup, Container, Grid, TextField,
} from '@mui/material/';
import SortIcon from '@mui/icons-material/Sort';
import Header from 'components/Header/Header';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import FileList from 'components/FileList/FileList';
import FileUploadButton from 'components/FileUploadButton/FileUploadButton';
import UserContext from 'context/UserContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useStyles } from './FileManagement.jss';
import { IRow } from './FileManagement.types';

const FileManagement: React.FunctionComponent = () => {
  const classes = useStyles;
  const dateFormat = 'YYYY-MM-DD';
  const [selectedDate, setDateChange] = useState<Moment | null>(moment().subtract(7, 'd'));
  const [filetype, setFileType] = useState('outbound');
  const [url, setUrl] = useState('');
  const [sortDirection, setSortDirection] = useState(false);

  const type = useContext(UserContext)?.type;
  const name = useContext(UserContext)?.name || '';
  const displayName = name.substring(0, 20);

  useEffect(() => {
    if (selectedDate && selectedDate.isValid()) {
      const temp = selectedDate.clone();
      const fromdate = temp.format(dateFormat);
      const todate = temp.add(8, 'd').format(dateFormat);
      setUrl(`/files/list?fromdate=${fromdate}&todate=${todate}`);
    }
  }, [selectedDate]);

  const {
    loading, data, error,
  } = useAxiosGet<IRow[]>(url, [url]);

  const renderFileUpload = ():JSX.Element => {
    if (type !== 'VENDOR') { return <FileUploadButton />; }
    return <div />;
  };

  return (
    <Container>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={classes.headerItem}>
        <Grid item style={{ marginBottom: '0px' }}>
          <Header text="File Status" period />
        </Grid>
        <Grid item>
          { renderFileUpload() }
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={classes.headerItem}>
        <Grid item>
          <Header variant="h4" text="SEARCH BY START DATE" short />
        </Grid>
        <Grid item>
          <DatePicker
            value={selectedDate}
            onChange={(newValue) => { setDateChange(newValue); }}
            renderInput={(params) => <TextField sx={classes.datePicker} variant="standard" {...params} />}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <ButtonGroup sx={classes.buttonGroup} variant="text" color="primary" aria-label="text primary button group">
                <Button color={filetype === 'outbound' ? 'secondary' : 'primary'} onClick={() => setFileType('outbound')}>To</Button>
                <Button color={filetype === 'inbound' ? 'secondary' : 'primary'} onClick={() => setFileType('inbound')}>From</Button>
              </ButtonGroup>
            </Grid>
            <Grid item />
            <Grid item>
              <Header variant="h3" text={displayName} period space="left" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button sx={classes.sortButton} onClick={() => setSortDirection(!sortDirection)}><SortIcon sx={classes.icon} /></Button>
        </Grid>
        <FileList sortDirection={sortDirection} rows={data} type={filetype} loading={loading} error={error} />
      </Grid>
    </Container>
  );
};

export default FileManagement;
