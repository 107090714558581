import Header from 'components/Header/Header';
import React from 'react';

const Docusign: React.FunctionComponent = () => (
  <span>
    <Header text="Your document was successful e-signed. Please close the modal to proceed" period />
  </span>
);

export default Docusign;
