const useStyles = {
  box: {
    display: 'grid',
    gridTemplateColumns: {
      sm: '3fr 3fr',
    },
    gap: 3,
  },
  spouseButton: {
    mr: 1,
    ml: 1,
  },
  accordion: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },
  formControl: {
    width: '100%',
  },
};

export { useStyles };
