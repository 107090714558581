import React, {
  useState, useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid, CardContent, Typography, Button, Card, CircularProgress,
} from '@mui/material';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import { getConfig } from 'api/util/getConfig';
import OliAxios from 'api/util/OliAxios';
import UserContext from 'context/UserContext';
import { useStyles } from './Cards.jss';
import MatterDialog from './MatterDialog';

// eslint-disable-next-line
const Cards: React.FunctionComponent = () => {
  const classes = useStyles;
  type MatterHomeData = {
    external_account_id: string
    ssn: string
    decryptedSsn: string
    account_number: string
    current_debt_balance: string
    status: string
    secure_ssn: string
    days_past_due: string
    last_payment_received_date: string
    last_payment_amount: string
  };
  const { matterUUID } = useParams();
  const token = useContext(UserContext)?.token;
  const [matterClose, setMatterClose] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');
  const [isOpenFail, setIsOpenFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(' ');
  const [showDecryptedSSN, setShowDecryptedSSN] = useState(false);
  const [showDecryptedAccountNumber, setShowDecryptedAccountNumber] = useState(false);
  const [decryptedSSN, setDecryptedSSN] = useState('');

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const {
    data, loading,
  } = useAxiosGet<MatterHomeData>(`/getMatterHome?matterID=${matterUUID}`, []);
  const clearFields = ():void => {
    setErrorMessage('');
    setNotes('');
    setReason('');
  };
  const saveCloseMatter = async (): Promise<void> => {
    if (reason === '' || notes === '') {
      setIsOpenFail(true);
      setIsSuccess(false);
      setErrorMessage('Enter reason and closing notes to proceed');
    } else {
      const matterData = {
        matterID: `${matterUUID}`,
        eventName: 'close matter',
        payLoad: {
          matterId: `${matterUUID}`,
          reason: `${reason}`,
          notes: `${notes}`,
        },
      };
      if (!token) return;
      try {
        const response = await OliAxios.post('/closeMatter', matterData, {
          ...getConfig(token),
        });
        if (response === undefined) {
          setIsOpenFail(true);
          setIsSuccess(false);
          setErrorMessage('Matter is already closed.');
        } else {
          setIsOpenFail(false);
          setIsSuccess(true);
          setMatterClose(false);
          clearFields();
        }
      } catch (e) {
        setIsOpenFail(true);
        setIsSuccess(false);
        setErrorMessage('api response failed , check with admin.');
      }
    }
  };
  const onChangeReason = (value: string): void => {
    setIsOpenFail(false);
    setReason(value);
  };
  const onChangeNotes = (value: string): void => {
    setIsOpenFail(false);
    setNotes(value);
  };
  const cancelCloseMatter = (): void => {
    setIsSuccess(false);
    setIsOpenFail(false);
    setMatterClose(false);
    clearFields();
  };

  const openCloseMatter = (): void => {
    setIsSuccess(false);
    setIsOpenFail(false);
    setMatterClose(true);
  };

  const decryptSSN = async ():Promise<void> => {
    if (token && showDecryptedSSN === false) {
      const config = getConfig(token);
      const url = '/util/decrypt';
      const params = {
        decrypt: data?.secure_ssn,
      };
      const result = await OliAxios.get(url, {
        ...config, params,
      });
      if (result && result.data) {
        setShowDecryptedSSN(true);
        setDecryptedSSN(result.data);
        setTimeout(() => { setShowDecryptedSSN(false); }, 15000);
      }
    }
  };

  const decryptAccountNumber = ():void => {
    setShowDecryptedAccountNumber(true);
    setTimeout(() => { setShowDecryptedAccountNumber(false); }, 15000);
  };

  const contactCard = (
    <>
      <CardContent>
        <Typography sx={classes.typoTitle} color="text.secondary" gutterBottom>
          Matter Reference
        </Typography>
        <Typography sx={classes.typoData}>
          {data?.external_account_id || data?.account_number}
        </Typography>
        <Typography sx={classes.typoTitle} color="text.secondary" gutterBottom>
          Matter Status
        </Typography>
        <Typography sx={classes.typoData}>
          {data?.status}
        </Typography>
        <Typography
          sx={{
            ...classes.typoTitle, ...classes.pointer,
          }}
          color="text.secondary"
          gutterBottom
          onClick={() => { decryptAccountNumber(); }}
        >
          Account Number
        </Typography>
        <Typography
          sx={{
            ...classes.typoData, ...classes.pointer,
          }}
          onClick={() => { decryptAccountNumber(); }}
        >
          { showDecryptedAccountNumber ? data?.account_number : data?.account_number?.replace(/.(?=.{4,}$)/g, 'X')}
        </Typography>
        <Typography
          sx={{
            ...classes.typoTitle, ...classes.pointer,
          }}
          color="text.secondary"
          gutterBottom
          onClick={() => { decryptSSN(); }}
        >
          Primary SSN
        </Typography>
        <Typography
          sx={{
            ...classes.typoData, ...classes.pointer,
          }}
          onClick={() => { decryptSSN(); }}
        >
          {showDecryptedSSN ? decryptedSSN : data?.ssn}
        </Typography>
        <Typography sx={classes.typoTitle} color="text.secondary" gutterBottom>
          Current Balance
        </Typography>
        <Typography sx={classes.typoData}>
          {USDollar.format(data?.current_debt_balance as unknown as number)}
        </Typography>
      </CardContent>
    </>
  );
  const quickActionCard = (
    <>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Quick Actions
        </Typography>
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={openCloseMatter}>Close Matter</Button>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
  const dataCard = (
    <>
      <CardContent>
        <Typography sx={classes.typoTitle} color="text.secondary" gutterBottom>
          Last Payment Date
        </Typography>
        <Typography sx={classes.typoData}>
          {data?.last_payment_received_date}
        </Typography>
        <Typography sx={classes.typoTitle} color="text.secondary" gutterBottom>
          Last Payment Amount
        </Typography>
        <Typography sx={classes.typoData}>
          $
          {data?.last_payment_amount}
        </Typography>
        <Typography sx={classes.typoTitle} color="text.secondary" gutterBottom>
          Days Past Due
        </Typography>
        <Typography sx={classes.typoData}>
          {data?.days_past_due}
        </Typography>
      </CardContent>
    </>
  );

  const renderDataCard = (): JSX.Element => {
    if (!loading) {
      return dataCard;
    }
    return (
      <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
        <Grid item sx={{ height: 310 }}>
          <br />
          <CircularProgress />
        </Grid>
      </Grid>
    );
  };

  const renderContactCard = (): JSX.Element => {
    if (!loading) {
      return contactCard;
    }
    return (
      <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
        <Grid item sx={{ height: 310 }}>
          <br />
          <CircularProgress />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <MatterDialog
        openFail={isOpenFail}
        notes={notes}
        onChangeNotes={onChangeNotes}
        reason={reason}
        errorMessage={errorMessage}
        onChangeReason={onChangeReason}
        matterClose={matterClose}
        isSuccess={isSuccess}
        saveCloseMatter={saveCloseMatter}
        cancelCloseMatter={cancelCloseMatter}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={6} md={3}>
          <Card variant="outlined">{renderContactCard()}</Card>
        </Grid>
        <Grid item xs={6} md={3}>
          <Card variant="outlined" sx={{ height: '100%' }}>{renderDataCard()}</Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ height: '100%' }}>{quickActionCard}</Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Cards;
