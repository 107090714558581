import {
  Outlet, Routes, Route,
} from 'react-router-dom';
import Header from 'components/Header/Header';
import {
  Container,
} from '@mui/material';
import React from 'react';
import DocPrepWorkflow from './DocPrepWorkflow';
import DocPrepList from './DocPrepList';

const DocPrep: React.FunctionComponent = () => (
  <Container maxWidth={false}>
    <Header text="Doc Prep" period />
    <div>
      <Routes>
        <Route index element={<DocPrepList />} />
        <Route path="packets/:docId/*" element={<DocPrepWorkflow />} />
      </Routes>
      <Outlet />
    </div>
  </Container>
);

export default DocPrep;
