import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import {
  CustomerListResponse, CustomerListResponseData,
} from './Customer.types';

type Methods = 'head' | 'options' | 'put' | 'post' | 'patch' | 'delete' | 'get';

async function getList(token:string | undefined, matterUUID: string | undefined):Promise<undefined | CustomerListResponseData> {
  if (token) {
    const config = getConfig(token);
    const url = '/customer/list';
    const params = { matterUuid: matterUUID };
    try {
      const response:CustomerListResponse = await OliAxios.get(url, {
        ...config, params,
      });
      return response.data;
    } catch (e) {
      console.log("Something went wrong", e); // eslint-disable-line
    }
  }
  return undefined;
}

async function postCustomer(token:string | undefined, action: string, data: Record<string, unknown>):Promise<undefined> {
  if (token) {
    const config = getConfig(token);
    const url = '/postCustomerData';
    const params = {
      action, data,
    };

    let method:Methods;
    switch (action) {
      case 'create':
        method = 'post';
        break;
      case 'delete':
        method = 'delete';
        break;
      case 'update':
        method = 'patch';
        break;
      default:
        method = 'get';
        break;
    }
    try {
      await OliAxios({
        method, url, data: params, ...config,
      });
    } catch (e) {
      console.log("Something went wrong", e); // eslint-disable-line
    }
  }
  return undefined;
}

export {
  getList, postCustomer,
};
