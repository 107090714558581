import { getConfig } from 'api/util/getConfig';
import OliAxios from 'api/util/OliAxios';
import { AxiosResponse } from 'axios';

const patchScrub = async (token:string | undefined, params:unknown):Promise<AxiosResponse<unknown> | undefined> => {
  if (!token) return undefined;
  const config = getConfig(token);
  const url = '/scrub';
  return OliAxios.patch(url, params, config);
};

export {
  patchScrub,
};
