import React, {
  useState, useContext,
} from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import UserContext from 'context/UserContext';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import { getConfig } from 'api/util/getConfig';
import { AxiosResponse } from 'axios';
import OliAxios from 'api/util/OliAxios';
import Header from 'components/Header/Header';
import { SignatureReviewModalProps } from './SignatureReview.types';

const SignatureReviewModal:React.FunctionComponent<SignatureReviewModalProps> = (props) => {
  const token = useContext(UserContext)?.token;
  const [approving, setApproving] = useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const {
    open, handleClose, modalData,
  } = props;
  const [reason, setReason] = useState<string[]>([]);

  type SignatureReviewView = {
    downloadURL: string
  };

  const {
    data, loading,
  } = useAxiosGet<SignatureReviewView>(`/signatureReviewView?path=${modalData?.s3_key}`, []);

  const reviewPDF = async (review: string):Promise<AxiosResponse<unknown> | undefined> => {
    if (!token) return undefined;
    const config = getConfig(token);
    const url = '/documentmanagement/signedReview';
    const params = {
      review, signature_review_uuid: modalData?.signature_review_uuid, rejection_reasons: reason, matterUuid: modalData?.matter_uuid,
    };
    return OliAxios.get(url, {
      ...config, params,
    });
  };

  const approvedClicked = async ():Promise<void> => {
    setApproving(true);
    await reviewPDF('approve');
    setApproving(false);
    handleClose({}, 'button');
  };

  const rejectClicked = async ():Promise<void> => {
    setApproving(true);
    await reviewPDF('reject');
    setApproving(false);
    handleClose({}, 'button');
  };

  const rejectionReasons = [
    {
      key: 'Mitigation Screen', label: 'Mitigation Screen',
    },
    {
      key: 'Monetary Amount/Terms', label: 'Monetary Amount/Terms',
    },
    {
      key: 'Entity/Buyer', label: 'Entity/Buyer',
    },
    {
      key: 'Customer Name', label: 'Customer Name',
    },
    {
      key: 'Spelling Error', label: 'Spelling Error',
    },
    {
      key: 'Signature/Date', label: 'Signature/Date',
    },
    {
      key: 'SCRA', label: 'SCRA',
    },
    {
      key: 'Case Number', label: 'Case Number',
    },
    {
      key: 'Redaction', label: 'Redaction',
    },
    {
      key: 'Exhibits', label: 'Exhibits',
    },
    {
      key: 'Document Error', label: 'Document Error',
    },
    {
      key: 'Complaint Not Validated', label: 'Complaint Not Validated',
    },
    {
      key: 'SHS', label: 'SHS',
    },
    {
      key: 'Notary', label: 'Notary',
    },
    {
      key: 'Other', label: 'Other',
    },
    {
      key: 'Incorrect Date', label: 'Incorrect Date',
    },
    {
      key: 'Employee Name/Title', label: 'Employee Name/Title',
    },
    {
      key: 'State Statue', label: 'State Statue',
    },
    {
      key: 'Action Type', label: 'Action Type',
    },
  ];

  const handleChange = (event: SelectChangeEvent<typeof reason>):void => {
    const {
      target: { value },
    } = event;
    setReason(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const renderIframe = ():JSX.Element => {
    if (data?.downloadURL && modalData?.s3_key) {
      return (
        <iframe
          style={{
            width: '100%', minHeight: '750px',
          }}
          title="preview"
          src={data?.downloadURL}
        />
      );
    }
    if (!modalData?.s3_key) {
      return <Header text="Signed File Not Found" period />;
    }
    return <></>;
  };

  const renderApprovalContent = ():JSX.Element => (
    <>
      <DialogTitle>Approve or Reject</DialogTitle>
      <DialogContent>
        {renderIframe()}
      </DialogContent>
      <DialogActions>
        <FormControl sx={{
          minWidth: '500px', marginRight: '10px',
        }}
        >
          <InputLabel id="reject-reason-select-label">Reject Reason</InputLabel>
          <Select
            labelId="reject-reason-select-label"
            id="reject-reason-select"
            label="Reject Reason"
            multiple
            value={reason}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            {rejectionReasons.map((rr) => (
              <MenuItem
                key={rr.key}
                value={rr.label}
              >
                {rr.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="error" disabled={loading || approving} onClick={() => handleClose({}, 'button')}>Cancel</Button>
        <Button variant="contained" color="error" disabled={loading || reason.length === 0 || approving} onClick={rejectClicked}>Reject</Button>
        <Button variant="contained" color="primary" disabled={loading || approving || !modalData?.s3_key} onClick={approvedClicked}>Approve</Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
      {renderApprovalContent()}
    </Dialog>
  );
};

export default SignatureReviewModal;
