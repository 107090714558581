import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  useParams, useSearchParams, useNavigate,
} from 'react-router-dom';

import {
  Box,
  Button, Card, CircularProgress, DialogActions, DialogContent, Grid, Snackbar,
} from '@mui/material';
import moment from 'moment/moment';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicator from '@mui/icons-material/DragIndicator';
import {
  DocPrepProps,
} from './DocPrep.types';
import OliMaterialTable from '../OliMaterialTable/OliMaterialTable';
import UserContext from '../../context/UserContext';
import OliAxios from '../../api/util/OliAxios';
import { getConfig } from '../../api/util/getConfig';
import {
  Data, DownloadParams, ErrorNotFound, PreSignedURLResponse,
} from '../FileTransfer/FileTransfer.types';
import { Action } from '../OliMaterialTable/OliMaterialTable.types';
import { downloadFile } from '../FileTransfer/List/List.api';
import analytics from '../../Analytics';
import {
  concatenatePDFs, getPresignedUrl,
} from './DocPrepWorkflow.api';

const MERGED_TEMP_BUCKET = process.env.REACT_APP_MERGED_TEMP_BUCKET;
const SUPPORTING_FILE_BUCKET = process.env.REACT_APP_SUPPORTING_BUCKET;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line max-lines-per-function
const DocPrepExhibits: React.FunctionComponent<DocPrepProps> = ({
  loading, setLoading, docPrepData, setActiveStep,
}) => {
  const navigate = useNavigate();
  const {
    docId,
    filename,
  } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [presignedUrl, setPresignedURL] = useState<string>();

  const [documents, setDocuments] = useState<Data[]>([] as Data[]);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);

  const [exhibits, setExhibits] = useState<Data[]>([] as Data[]);

  const [dragging, setDragging] = useState<{ current: number }>({ current: 0 });
  const [error, setError] = useState<Error>();
  const [openFail, setOpenFail] = useState(false);

  const token = useContext(UserContext)?.token;

  const handleSuccess = (result: Data[] | ErrorNotFound): void => {
    setLoadingDocuments(false);
    if (result instanceof Array) {
      setDocuments(result);
    }
  };

  const getDocuments = async (): Promise<void> => {
    if (docPrepData.matter_uuid && token) {
      setLoadingDocuments(true);
      const config = getConfig(token);
      const url = '/fileTransfer/list';
      const params = {
        matter_uuid: docPrepData.matter_uuid,
      };
      await OliAxios.get(url, {
        ...config, params,
      }).then((result) => handleSuccess(result.data));
    }
  };

  useEffect(() => {
    if (token) {
      getDocuments();
    }
  }, [docPrepData.matter_uuid]);

  const formatData = (filedata: Data[] = []): Data[] => filedata.map((element) => ({
    ...element, uploaded: moment(element.uploaded).format('MM/DD/YYYY'),
  }));

  const fetchPresignedUrl = async (): Promise<void> => {
    if (!token) return;

    const resp = await getPresignedUrl(token, process.env.REACT_APP_MERGED_TEMP_BUCKET, filename);

    if (!resp) return;

    setPresignedURL(resp?.data.url);
  };

  useEffect(() => {
    setActiveStep(2);

    fetchPresignedUrl();
  }, [filename]);

  const renderPDFPreview = (): JSX.Element => {
    if (presignedUrl) {
      return (
        <div>
          <iframe
            style={{
              width: '100%', minHeight: '750px',
            }}
            title="preview"
            src={presignedUrl}
          />
        </div>
      );
    }
    return <></>;
  };

  const viewFile = (rowData: Data): void => {
    const params: DownloadParams = {
      path: rowData.path, fileID: rowData.id, isDownload: false,
    };
    downloadFile(token, params);
  };

  const handleAddExhibit = (rowData: Data): void => {
    if (!(exhibits).some((item: Data): boolean => item.id === rowData.id)) {
      searchParams.set('exhibits', JSON.stringify([
        ...exhibits,
        {
          ...rowData,
          bucket: SUPPORTING_FILE_BUCKET as string,
        },
      ]));
      setSearchParams(searchParams, { replace: true });
    }
  };

  const handleUploadSuccess = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;
    if (!files) return;

    const rowData: Data = {
      id: '0', // TODO: Maybe random
      name: files[0].name,
      description: 'Uploaded Exhibit',
      tags: '',
      path: `temp/${files[0].name}`,
      bucket: MERGED_TEMP_BUCKET as string,
    };

    searchParams.set('exhibits', JSON.stringify([
      ...exhibits,
      rowData,
    ]));
    setSearchParams(searchParams, { replace: true });

    e.target.value = '';
  };

  useEffect(() => {
    renderPDFPreview();

    const urlExhibits = searchParams.get('exhibits');
    if (urlExhibits) {
      setExhibits(JSON.parse(urlExhibits));
    }
  }, [searchParams]);

  const actions: Action[] = [
    {
      icon: () => <VisibilityIcon />,
      tooltip: 'View File',
      onClick: (_e, rowData) => viewFile(rowData as Data),
    },
    {
      icon: () => <AddIcon data-testid="add-icon" />,
      tooltip: 'Add to Exhibits',
      onClick: (_e, rowData) => handleAddExhibit(rowData as Data),
    },
  ];

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, index: number): void => {
    setDragging({ current: index });
    e.dataTransfer.effectAllowed = 'move';
    const target = e.target as HTMLDivElement;
    target.style.opacity = '0.4';
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>): void => {
    const target = e.target as HTMLDivElement;
    target.style.opacity = '1';
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, index: number): void => {
    e.preventDefault();
    const draggedItem = exhibits[dragging.current];
    if (dragging.current === index) return;
    const dragExhibits = exhibits.filter((_, idx) => idx !== dragging.current);
    dragExhibits.splice(index, 0, draggedItem);
    setDragging({ current: index });

    searchParams.set('exhibits', JSON.stringify(dragExhibits));
    setSearchParams(searchParams, { replace: true });
  };

  const handleDeleteExhibit = (exhibitId: string): void => {
    searchParams.set('exhibits', JSON.stringify(exhibits.filter((item) => item.id !== exhibitId)));
    setSearchParams(searchParams, { replace: true });
  };

  const handleUploadError = (er: Error, e: ChangeEvent<HTMLInputElement>): void => {
    setOpenFail(true);
    setError(er);
    e.target.value = '';
  };

  const concatenateFinalPDF = async (): Promise<Record<string, string>> => {
    setLoading(true);
    const params = {
      s3Objects: [
        {
          bucket: MERGED_TEMP_BUCKET,
          key: filename,
        },
        ...exhibits.map((exhibit) => ({
          bucket: exhibit.bucket,
          key: exhibit.path,
        })),
      ],
    };
    const result = await concatenatePDFs(token, params);

    setLoading(false);

    if (result && result.status === 200) {
      return {
        concatendatedS3Key: result?.data.key || '',
      };
    }
    console.error('error merging document. result {}', result);
    return {};
  };

  const handleNavigateNext = async (): Promise<void> => {
    const concenatedResult = await concatenateFinalPDF();

    navigate(`/doc-prep/packets/${docId}/finalize/${encodeURIComponent(concenatedResult.concatendatedS3Key)}`);
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, retryCount = 2): Promise<void> => {
    if (!token) return;
    let retry = retryCount;
    const { files } = event.target;
    if (!files) return;
    try {
      const params = {
        bucket: MERGED_TEMP_BUCKET, key: `temp/${files[0].name}`,
      };
      const response: PreSignedURLResponse = await OliAxios.get(`${process.env.REACT_APP_API_URL}/documents/upload`, {
        ...getConfig(token),
        params,
      });
      const options = {
        headers: {
          'Content-Type': files[0].type,
        },
      };

      const uploadPromises = response.data.uploadurls.map((urlObj, index) => OliAxios.put(urlObj.uploadURL, files[index], options));
      await Promise.all(uploadPromises);
      analytics.track('exhibit-upload');
    } catch (e) {
      if (retry < 2) {
        handleFileChange(event, retry += 1);
      } else {
        throw e as Error;
      }
    }
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    handleFileChange(e)
      .then(() => { handleUploadSuccess(e); })
      .catch((er) => handleUploadError(er, e));
  };

  const closeSnackBar = (): void => {
    setOpenFail(false);
  };
  const columns = [
    {
      title: 'Tags',
      field: 'description',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
    {
      title: 'Name',
      field: 'name',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
    {
      title: 'Uploaded On',
      field: 'uploaded',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
    {
      title: 'Creditor Create Date',
      field: 'sor_creation_date',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
  ];

  const renderDocumentsList = (): JSX.Element => (
    <OliMaterialTable
      actions={actions}
      columns={columns}
      loading={loadingDocuments}
      data={formatData(documents)}
      pagesize={5}
      selectable={false}
      filter
    />
  );

  const renderSelectedExhibits = (): JSX.Element => (
    <Card sx={{
      padding: '10px',
    }}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <h2>Selected Exhibits</h2>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <span>
            <input
              accept=".pdf"
              id="file"
              type="file"
              onChange={onFileChange}
              hidden
            />
            <label htmlFor="file">
              <Button color="secondary" variant="contained" component="span">
                Upload Exhibit
              </Button>
            </label>
          </span>
        </Grid>
      </Grid>
      <div>
        {exhibits.map((item, index) => (
          <Card
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragEnd={handleDragEnd}
            onDragOver={(e) => handleDragOver(e, index)}
            key={item.id}
            data-testid={`exhibit-card-${item.id}`}
            sx={{
              padding: '10px',
              margin: '10px',
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                minWidth: 0,
                position: 'relative',
              }}
            >
              <Grid
                item
                xs={2}
                sx={{
                  width: 'auto',
                  flexShrink: 0,
                  zIndex: 1,
                }}
              >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
                >
                  <DragIndicator sx={{ cursor: 'grab' }} />
                  <Button data-testid={`exhibit-delete-${item.id}`} onClick={() => handleDeleteExhibit(item.id)}><DeleteIcon /></Button>
                </Box>
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  flexGrow: 1,
                  minWidth: 0,
                  width: 'auto',
                  pl: 2,
                  '& span': {
                    display: 'block',
                    overflow: 'hidden',
                    textOverride: 'ellipsis',
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                {`Exhibit ${String.fromCharCode(65 + index)}`}
                {': '}
                {item.description}
                {' - '}
                {item.name}
              </Grid>
            </Grid>
          </Card>
        ))}
      </div>
    </Card>
  );

  return (
    <>

      <>
        <DialogContent>
          <br />
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={6} sx={{ paddingRight: '1.9%' }}>
              {renderPDFPreview()}
            </Grid>
            <Grid item xs={6}>
              {renderDocumentsList()}
              {renderSelectedExhibits()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Snackbar
            open={openFail}
            onClose={closeSnackBar}
          >
            <Alert onClose={closeSnackBar} severity="error" sx={{ width: '100%' }}>
              Something has gone wrong. (
              {error?.message}
              )
            </Alert>
          </Snackbar>
          <Button
            color="inherit"
            onClick={() => navigate(-1)}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNavigateNext}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size={20}
                sx={{
                  marginRight: '0.5rem',
                }}
              />
            )}
            Next
          </Button>
        </DialogActions>
      </>
    </>
  );
};

export default DocPrepExhibits;
