type AuthHeader = {
  Authorization: string | undefined;
};

type Config = {
  headers: AuthHeader;
};

export const getConfig = (token: string): Config => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

