const useStyles = {
  uploadButton: {
    marginRight: '27px',
  },
  alert: {
    width: '100%',
  },
};

export { useStyles };
