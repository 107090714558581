import React, {
  useState, useContext,
} from 'react';
import {
  Button, Dialog, DialogContent, DialogTitle, DialogActions, Box, SelectChangeEvent, Select, MenuItem, InputLabel, FormControl,
} from '@mui/material';
import OliTextField from 'components/Shared/OliTextField/OliTextField';
import UserContext from 'context/UserContext';
import StateSelect from 'components/Shared/StateSelect/StateSelect';

import {
  AttorneyListData, AttoryneyModalProps,
} from './Attorney.types';
import { postAttorney } from './Attorney.api';

const AttorneyModal:React.FunctionComponent<AttoryneyModalProps> = (props) => {
  const token = useContext(UserContext)?.token;
  const {
    open, handleClose, modalData, customerData,
  } = props;

  // console.log(customerData);

  const renderItems = ():JSX.Element[] | undefined => customerData.map((item) => (
    <MenuItem key={item.customer_uuid} value={item.customer_uuid}>
      {item.first_name}
      {' '}
      {item.last_name}
    </MenuItem>
  ));

  const setupFormData = ():Record<string, unknown> => {
    const formdata:Record<string, unknown> = {};
    Object.keys(modalData as AttorneyListData).forEach((key) => {
      formdata[key] = (modalData as AttorneyListData)[key as keyof AttorneyListData];
    });
    return formdata;
  };

  const [formData, setFormData] = useState<Record<string, unknown>>(setupFormData());
  const [loading, setLoading] = useState<boolean>(false);

  const handleFieldChange = (event:React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>, key: string):void => {
    setFormData({
      ...formData, [key]: event.target.value,
    });
  };

  const handleSubmitClick = async ():Promise<void> => {
    setLoading(true);
    const action = formData?.attorney_uuid ? 'update' : 'create';
    await postAttorney(token, action, formData);
    handleClose({}, 'button');
    setLoading(false);
  };

  const isValid = ():boolean => {
    let valid = true;
    const required = [
      'name', 'customers_uuid', 'address_line_1', 'city', 'country', 'postal_code',
      'state_prov_code', 'bar_number', 'affiant_name', 'affiant_bar_number', 'salutation',
      'email', 'phone',
    ];
    required.forEach((r) => {
      if (!formData[r]) {
        valid = false;
      }
    });

    return valid;
  };

  const classes = {
    box: {
      display: 'grid',
      gridTemplateColumns: {
        sm: '2fr 2fr',
      },
      gap: 2,
    },
  };

  const renderModal = ():JSX.Element => {
    if (modalData) {
      return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" disableEscapeKeyDown>
          <DialogTitle>Attorney</DialogTitle>
          <DialogContent>
            <Box alignItems="flex-end" component="form" noValidate sx={classes.box}>
              <OliTextField
                value={formData.name as string}
                label="Name"
                handleChange={(event) => handleFieldChange(event, 'name')}
                disabled={loading}
                required
                error={!formData.name}
              />
              <FormControl variant="standard" sx={{ width: '100%' }}>
                <InputLabel id="select-label" error={!formData.customers_uuid}>Customer *</InputLabel>
                <Select
                  labelId="debtor"
                  id="demo-simple-select"
                  value={formData.customers_uuid}
                  label="Debtor"
                  variant="standard"
                  disabled={loading}
                  required
                  error={!formData.customers_uuid}
                  onChange={(event) => handleFieldChange(event as SelectChangeEvent<string>, 'customers_uuid')}
                >
                  {renderItems()}
                </Select>
              </FormControl>
              <OliTextField
                value={formData.address_line_1 as string}
                label="Address 1"
                handleChange={(event) => handleFieldChange(event, 'address_line_1')}
                disabled={loading}
                required
                error={!formData.address_line_1}
              />
              <OliTextField
                value={formData.address_line_2 as string}
                label="Address 2"
                handleChange={(event) => handleFieldChange(event, 'address_line_2')}
                disabled={loading}
              />
              <OliTextField
                value={formData.city as string}
                label="City"
                handleChange={(event) => handleFieldChange(event, 'city')}
                disabled={loading}
                required
                error={!formData.city}
              />
              <OliTextField
                value={formData.country as string}
                label="Country"
                handleChange={(event) => handleFieldChange(event, 'country')}
                disabled={loading}
                required
                error={!formData.country}
              />
              <OliTextField
                value={formData.postal_code as string}
                label="Postal Code"
                handleChange={(event) => handleFieldChange(event, 'postal_code')}
                disabled={loading}
                required
                error={!formData.postal_code}
              />
              <StateSelect
                label="State *"
                value={formData.state_prov_code as string}
                loading={loading}
                error={!formData.state_prov_code}
                handleChange={(event) => handleFieldChange(event, 'state_prov_code')}
              />
              <OliTextField
                value={formData.bar_number as string}
                label="Bar Number"
                handleChange={(event) => handleFieldChange(event, 'bar_number')}
                disabled={loading}
                required
                error={!formData.bar_number}
              />
              <OliTextField
                value={formData.affiant_name as string}
                label="Affiant Name"
                handleChange={(event) => handleFieldChange(event, 'affiant_name')}
                disabled={loading}
                required
                error={!formData.affiant_name}
              />
              <OliTextField
                value={formData.affiant_bar_number as string}
                label="Affiant Bar Number"
                handleChange={(event) => handleFieldChange(event, 'affiant_bar_number')}
                disabled={loading}
                required
                error={!formData.affiant_bar_number}
              />
              <OliTextField
                value={formData.salutation as string}
                label="Salutation"
                handleChange={(event) => handleFieldChange(event, 'salutation')}
                disabled={loading}
                required
                error={!formData.salutation}
              />
              <OliTextField
                value={formData.email as string}
                label="Email"
                handleChange={(event) => handleFieldChange(event, 'email')}
                disabled={loading}
                required
                error={!formData.email}
              />
              <OliTextField
                value={formData.phone as string}
                label="Phone"
                handleChange={(event) => handleFieldChange(event, 'phone')}
                disabled={loading}
                required
                error={!formData.phone}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" disabled={loading} onClick={() => handleClose({}, 'button')}>Cancel</Button>
            <Button variant="contained" color="primary" disabled={loading || !isValid()} onClick={handleSubmitClick}>Submit</Button>
          </DialogActions>
        </Dialog>
      );
    }
    return <></>;
  };

  return (
    <>
      { renderModal() }
    </>
  );
};

export default AttorneyModal;
