import './HomepageDashboardV2.css';

import {
  embedDashboard,
  EmbedDashboardParams,
} from '@superset-ui/embedded-sdk';

import React, { ReactElement } from 'react';
import UserContext from 'context/UserContext';
import {
  Container,
} from '@mui/material';

import { fetchGuestToken } from './Dashboards.api';

class HomepageDashboardV2 extends React.Component {
  iframeRef = React.createRef<HTMLIFrameElement>();

  componentDidMount(): void {
    embedDashboard({
      id: process.env.REACT_APP_SUPERSET_HOME_DASHBOARD_V2_ID,
      supersetDomain: process.env.REACT_APP_SUPERSET_V2_URL,
      mountPoint: this.iframeRef.current as HTMLIFrameElement,
      fetchGuestToken: () => fetchGuestToken((this.context as typeof UserContext).token),
      dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional), urlParams (optional)
        hideTitle: true,
        filters: {
          expanded: true,
        },
      },
      // optional additional iframe sandbox attributes
      iframeSandboxExtras: ['allow-top-navigation', 'allow-popups-to-escape-sandbox'],
    } as EmbedDashboardParams);
  }

  render(): ReactElement {
    return (
      <Container maxWidth="xl">
        <div className="embedded-superset" ref={this.iframeRef} />
      </Container>
    );
  }
}

HomepageDashboardV2.contextType = UserContext;

export default HomepageDashboardV2;
