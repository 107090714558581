import { getConfig } from 'api/util/getConfig';
import OliAxios from 'api/util/OliAxios';
import {
  GuestTokenResponse,
  GetDashboardResponse,
  GetDashboard,
} from './Dashboards.types';

const fetchGuestToken = async (token: string | undefined): Promise<string> => {
  if (!token) return '';
  const config = getConfig(token);
  const url = '/dashboards/auth';
  const resp: GuestTokenResponse = await OliAxios.get(url, config);
  return resp.data.token;
};

const getDashboard = async (token: string | undefined, slug: string | undefined): Promise<GetDashboard | undefined> => {
  if (!token || !slug) return undefined;

  const config = getConfig(token);
  const url = `${process.env.REACT_APP_API_URL}/dashboards/${slug}`;
  const resp: GetDashboardResponse = await OliAxios.get(url, config);
  return resp.data;
};

export {
  fetchGuestToken,
  getDashboard,
};
