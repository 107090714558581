import React, { useState } from 'react';
import {
  Dialog, DialogContent, DialogActions, Typography, Button,
} from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';

interface IIdleTimerProps {
  signOut: () => void,
}

const IdleTimer:React.FunctionComponent<IIdleTimerProps> = (props) => {
  const [open, setOpen] = useState(false);

  const handleClose = ():void => {
    setOpen(false);
  };

  const handleSignOut = ():void => {
    // eslint-disable-next-line react/destructuring-assignment
    props.signOut();
  };

  const handleAction = ():void => {
    // make call to server
    // console.log('make activity call to server'); // eslint-disable-line
  };

  const timeout = 1000 * 60 * 15; // 15 minutes

  /* eslint-disable */
  const {
    isIdle,
  } = useIdleTimer({
    timeout,
    onIdle: handleSignOut,
    onAction: handleAction,
    throttle: 1000 * 45, // 45 seconds
    crossTab: {
      emitOnAllTabs: false,
    },
  });
  /* eslint-enable */

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={open}>
      <DialogContent dividers>
        <Typography gutterBottom>
          Your session is expired and you will have to log back in.
        </Typography>
        <DialogActions>
          <Button variant="contained" onClick={handleSignOut} color="secondary">
            Sign Out
          </Button>
          <Button variant="contained" onClick={handleClose} color="primary">
            Extend Session
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default IdleTimer;
