const useStyles = {
  button: {
    color: 'white',
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: '#3e3842',
    zIndex: 1201,
  },
  superSetAppBar: {
    backgroundColor: 'rgba(61, 55, 65, .5)',
  },
  sso: {
    paddingBottom: '24px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  ssoButton: {
    width: '100%',
  },
  logo: {
    paddingTop: '10px',
    paddingLeft: '8px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
};

export { useStyles };
