import {
  Menu, MenuItem,
} from '@mui/material';
import {
  Link,
} from 'react-router-dom';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import React from 'react';
import { SupersetMenuProps } from './Superset.types';

const SupersetMenu: React.FunctionComponent<SupersetMenuProps> = (props) => {
  const {
    anchorEl, setAnchorElInsights,
  } = props;

  const justClose = (): void => {
    setAnchorElInsights(null);
  };

  const styles = {
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
  };

  type AnalyticNavigation = {
    data: {
      navigation_first_level_label: string
      url_path: string
      embedded_uuid: string
    }[]
  };

  const url = `${process.env.REACT_APP_API_URL}/dashboards/`;
  const {
    loading, data,
  } = useAxiosGet<AnalyticNavigation>(url, [url]);

  const renderMenuOptions = (): JSX.Element[] | undefined => {
    if (loading) return undefined;
    return data?.data.map((nav) => {
      const navPath = `/insights/${nav.url_path}`;

      return (
        <MenuItem key={nav.url_path} sx={styles.link} component={Link} to={navPath} onClick={justClose}>{nav.navigation_first_level_label}</MenuItem>
      );
    });
  };

  return (
    <>
      <Menu
        id="insight-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={justClose}
      >
        {renderMenuOptions()}
      </Menu>
    </>
  );
};

export default SupersetMenu;
