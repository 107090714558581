const useStyles = {
  formControl: {
    minWidth: 359,
  },
  downloadButton: {
    marginTop: '12px',
  },
};

export { useStyles };
