import React, {
  useState, useContext,
} from 'react';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import {
  Grid, FormControl, InputLabel, Select, MenuItem,
  SelectChangeEvent, TextField, Button,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import MergeType from '@mui/icons-material/MergeType';
import UserContext from 'context/UserContext';
import OliAxios from 'api/util/OliAxios';
import { AxiosStatic } from 'axios';
import { getConfig } from 'api/util/getConfig';
import {
  MaterialTableProps, Query,
} from '@material-table/core';
import {
  DownloadParams, FileTransferDashboardData,
} from '../FileTransfer.types';
import {
  Action, MTQuery,
} from '../../OliMaterialTable/OliMaterialTable.types';
import {
  downloadFile, downloadMultipleFile, combineAndDownload,
} from '../List/List.api';
import columns from './DashboardColumns';

const FileTransferDashboard: React.FunctionComponent = () => {
  const tableRef = React.useRef();
  const [sort, setSort] = useState('ASC');
  const [fileName, setFileName] = useState('');
  const [fromDate, setFromDate] = useState<Moment | null>(moment().subtract(1, 'd'));
  const [thruDate, setThruDate] = useState<Moment | null>(moment());
  const dateFormat = 'YYYY-MM-DD';
  const token = useContext(UserContext)?.token;

  const handleSortChange = (event: SelectChangeEvent): void => {
    setSort(event.target.value);
  };
  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFileName(event.target.value);
  };

  // eslint-disable-next-line
  const constructUrl = (query: Query<object>): string => {
    const qsp: string[] = [];
    if (fromDate) {
      qsp.push(`from=${fromDate.format(dateFormat)}`);
    }
    if (thruDate) {
      qsp.push(`thru=${thruDate.format(dateFormat)}`);
    }
    if (sort) {
      qsp.push(`sort=${sort}`);
    }
    if (fileName) {
      qsp.push(`fileName=${fileName}`);
    }

    if (query.filters) {
      query.filters.forEach((f) => {
        qsp.push(`${f.column.field as string}=${f.value as string}`);
      });
    }

    qsp.push(`per_page=${query.pageSize}`);
    qsp.push(`page=${query.page + 1}`);

    const queryStringParams = qsp.join('&');
    if (queryStringParams.length > 0) {
      return `?${queryStringParams}`;
    }
    return '';
  };

  const grabData = (): void => {
    if (tableRef && tableRef.current) {
      (tableRef.current as MaterialTableProps<Record<string, unknown>>)?.onQueryChange?.();
    }
  };

  const handleView = async (filedata: FileTransferDashboardData[]): Promise<void> => {
    const promises: Promise<AxiosStatic | undefined>[] = [];
    filedata.forEach((d) => {
      const params: DownloadParams = {
        path: d.path, fileID: d.id, isDownload: false,
      };
      promises.push(downloadFile(token, params));
    });
    await Promise.all(promises);
  };

  const handleDownload = async (filedata: FileTransferDashboardData[]): Promise<void> => {
    const downloadParams: DownloadParams[] = [];
    filedata.forEach((d) => {
      const params: DownloadParams = {
        path: d.path, fileID: d.id, isDownload: true,
      };
      downloadParams.push(params);
    });
    if (downloadParams.length > 1) {
      await downloadMultipleFile(token, downloadParams);
    } else {
      const params: DownloadParams = {
        path: filedata[0].path, fileID: filedata[0].id, isDownload: true,
      };
      await downloadFile(token, params);
    }
  };

  const handleCombineAndDownload = async (filedata: FileTransferDashboardData[]):Promise<void> => {
    const inputPdfs = filedata.map((fd) => fd.path);
    await combineAndDownload(token, { inputPdfs });
  };

  const actions: Action[] = [{
    icon: () => <VisibilityIcon />,
    tooltip: 'View File',
    onClick: (_e, rowData) => handleView(rowData as FileTransferDashboardData[]),
  }, {
    icon: () => <DownloadIcon />,
    tooltip: 'Download File',
    onClick: (_e, rowData) => handleDownload(rowData as FileTransferDashboardData[]),
  }, {
    icon: () => <MergeType style={{ transform: 'scaleY(-1)' }} />,
    tooltip: 'Combine and Download',
    onClick: (_e, rowData) => handleCombineAndDownload(rowData as FileTransferDashboardData[]),
  }];

  return (
    <>
      <br />
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item>
          <TextField id="standard-basic" label="File Name" variant="standard" value={fileName} onChange={handleFileNameChange} />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item>
          <InputLabel id="date-from-select-label" sx={{ fontSize: '9pt' }}>From</InputLabel>
          <DatePicker
            value={fromDate}
            onChange={(newValue) => { setFromDate(newValue); }}
            renderInput={(params) => <TextField variant="standard" {...params} />}
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item>
          <InputLabel id="date-thru-select-label" sx={{ fontSize: '9pt' }}>Thru</InputLabel>
          <DatePicker
            value={thruDate}
            onChange={(newValue) => { setThruDate(newValue); }}
            renderInput={(params) => <TextField variant="standard" {...params} />}
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Sort</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sort}
              label="Sort"
              onChange={handleSortChange}
              fullWidth
              sx={{ minWidth: '100px' }}
            >
              <MenuItem value="ASC">Asc</MenuItem>
              <MenuItem value="DESC">Desc</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item>
          <Button color="secondary" variant="contained" component="span" onClick={() => grabData()}>
            Filter
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <br />
          <OliMaterialTable
            tableRef={tableRef}
            data={(query) => new Promise((resolve) => {
              const url = `/fileTransfer/dasboard${constructUrl(query)}`;
              OliAxios.get(url, { ...getConfig(token || '') })
                .then((response) => response.data as unknown as MTQuery)
                .then((result) => {
                  resolve({
                    data: result.data,
                    page: parseInt(result.page, 10) - 1,
                    totalCount: parseInt(result.total, 10),
                  });
                })
                .catch((e) => console.error(e));
            })}
            columns={columns}
            actions={actions}
            filter
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FileTransferDashboard;
