import React from 'react';
import { TextField } from '@mui/material';
import { OliTextFieldProps } from './OliTextField.types';

const OliTextField: React.FunctionComponent<OliTextFieldProps> = (props) => {
  const {
    label, value, required, error, disabled, handleChange,
  } = props;

  return (
    <>
      <TextField
        label={label}
        variant="standard"
        size="small"
        required={required || false}
        error={error || false}
        fullWidth
        onChange={handleChange}
        value={value || ''}
        disabled={disabled}
      />
    </>
  );
};

export default OliTextField;
