import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  useParams, useSearchParams, useNavigate,
} from 'react-router-dom';
import {
  Box, Button, CircularProgress, DialogActions, DialogContent,
} from '@mui/material';
import { Moment } from 'moment/moment';
import OliTextField from '../Shared/OliTextField/OliTextField';
import OliDateField from '../Shared/OliDateField/OliDateField';
import {
  DataField, DocPrepDataInputProps,
} from './DocPrep.types';
import { mergePDF } from './DocPrepWorkflow.api';
import UserContext from '../../context/UserContext';

const DocPrepDataInput: React.FunctionComponent<DocPrepDataInputProps> = ({
  loading, setLoading, docPrepData, setActiveStep,
}) => {
  useEffect(() => setActiveStep(0));

  const token = useContext(UserContext)?.token;
  const { docId } = useParams();
  const [formData, setFormData] = useState<Record<string, unknown>>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!docPrepData.doc) return;

    const urlFormData = searchParams.get('formData');

    const fieldValues: Record<string, unknown> = JSON.parse(urlFormData || '{}') as Record<string, unknown>;

    docPrepData.doc.data_fields.forEach((df: DataField): void => {
      if (!fieldValues[df.key]) {
        fieldValues[df.key] = docPrepData.doc.original_data[df.key] || df.defaultvalue;
      }
    });

    setFormData(fieldValues);
  }, [searchParams, docPrepData.doc]);

  const updateField = (key: string, value: string | undefined): void => {
    searchParams.set('formData', JSON.stringify({
      ...formData,
      [key]: value,
    }));
    setSearchParams(searchParams, { replace: true });
  };

  const isValid = (): boolean => {
    let valid = true;
    docPrepData.doc?.data_fields.forEach((df) => {
      if (df.required && (formData[df.key] === '' || !formData[df.key])) {
        valid = false;
      }
    });
    return valid;
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>, key: string): void => {
    updateField(key, event.target.value);
  };

  const handleDateFieldChange = (event: Moment | null, key: string): void => {
    updateField(key, event?.format('YYYY-MM-DD'));
  };

  const handleNavigateNext = async (): Promise<void> => {
    setLoading(true);

    const params = {
      templateDocx: docPrepData.doc?.template_reference,
      mergedData: formData,
      outputPdf: docPrepData.doc?.s3_key,
    };

    const result = await mergePDF(token, params);

    if (result) {
      setLoading(false);
      navigate(`/doc-prep/packets/${docId}/preview/${encodeURIComponent(result.data.s3key)}`);
    }
  };

  const renderFields = (): JSX.Element[] => {
    if (!docPrepData.doc) return [<></>];
    return docPrepData.doc.data_fields.map((df) => {
      if (df.type === 'string') {
        return (
          <React.Fragment key={df.key}>
            <OliTextField
              value={formData[df.key] as string}
              label={df.label}
              required={df.required}
              error={!formData[df.key] && df.required}
              handleChange={(event) => handleTextFieldChange(event, df.key)}
              disabled={loading}
            />
          </React.Fragment>
        );
      }
      if (df.type === 'date') {
        return (
          <React.Fragment key={df.key}>
            <OliDateField
              value={formData[df.key] as string}
              label={df.label}
              handleChange={(event) => handleDateFieldChange(event, df.key)}
              disabled={loading}
              error={!formData[df.key] && df.required}
              required={df.required}
            />
          </React.Fragment>
        );
      }

      return <React.Fragment key={df.key} />;
    });
  };

  return (
    <>
      <DialogContent>
        <br />
        <Box
          alignItems="flex-end"
          component="form"
          noValidate
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              sm: '2fr 2fr',
            },
            gap: 2,
          }}
        >
          {renderFields()}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={() => navigate(-1)}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!isValid() || loading}
          onClick={handleNavigateNext as () => void}
        >
          {loading && (
            <CircularProgress
              size={20}
              sx={{
                marginRight: '0.5rem',
              }}
            />
          )}
          Next
        </Button>
      </DialogActions>

    </>

  );
};

export default DocPrepDataInput;
