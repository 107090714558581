import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import {
  MTQuery,
} from 'components/OliMaterialTable/OliMaterialTable.types';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import UserContext from 'context/UserContext';
import {
  Container,
} from '@mui/material';
import { Query } from '@material-table/core';
import { TemplateGeneratedArtifactData } from './DocPrep.types';

const DocPrepList: React.FunctionComponent = () => {
  const tableRef = React.useRef();
  const navigate = useNavigate();
  const token = useContext(UserContext)?.token;

  // eslint-disable-next-line
  const constructUrl = (query: Query<object>): string => {
    const qsp: string[] = [];
    if (query.filters) {
      query.filters.forEach((f) => {
        qsp.push(`${f.column.field as string}=${f.value as string}`);
      });
    }

    qsp.push(`per_page=${query.pageSize}`);
    qsp.push(`page=${query.page + 1}`);

    if (query.orderBy && query.orderDirection) {
      qsp.push(`order=${query.orderBy.field as string}`);
      qsp.push(`orderDirection=${query.orderDirection}`);
    }

    const queryStringParams = qsp.join('&');
    if (queryStringParams.length > 0) {
      return `?${queryStringParams}`;
    }
    return '';
  };

  const columns = [
    {
      title: 'Matter Reference', field: 'matter_reference',
    },
    {
      title: 'Creation Date', field: 'created_on',
    },
    {
      title: 'Firm Name', field: 'firm_name',
    },
    {
      title: 'Oliver Description', field: 'template_reference',
    },
  ];

  const handleClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, rowData: TemplateGeneratedArtifactData): void => {
    navigate(`/doc-prep/packets/${rowData.id}`);
  };

  return (
    <Container maxWidth={false}>
      <div>
        <OliMaterialTable
          tableRef={tableRef}
          title=""
          columns={columns}
          data={(query) => new Promise((resolve) => {
            const url = `/interview/list${constructUrl(query)}`;
            OliAxios.get(url, { ...getConfig(token || '') })
              .then((response) => response.data as unknown as MTQuery)
              .then((result) => {
                resolve({
                  data: result.data,
                  page: parseInt(result.page, 10) - 1,
                  totalCount: parseInt(result.total, 10),
                });
              })
              .catch((e) => console.error(e));
          })}
          pagesize={20}
          selectable={false}
          onRowClick={handleClick}
          filter
        />
      </div>
    </Container>
  );
};

export default DocPrepList;
