import React, {
  useContext, useState,
} from 'react';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import {
  Action, MTQuery,
} from 'components/OliMaterialTable/OliMaterialTable.types';
import EditIcon from '@mui/icons-material/Edit';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import UserContext from 'context/UserContext';

import Header from 'components/Header/Header';
import {
  Container,
} from '@mui/material';

import {
  MaterialTableProps,
  Query,
} from '@material-table/core';
import SignatureReviewModal from './SignatureReviewModal';
import { SignatureReviewData } from './SignatureReview.types';

const SignatureReviewList: React.FunctionComponent = () => {
  const tableRef = React.useRef();
  const token = useContext(UserContext)?.token;
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<SignatureReviewData>();
  const handleOpen = (): void => setOpenModal(true);

  const getList = (): void => {
    if (tableRef && tableRef.current) {
      (tableRef.current as MaterialTableProps<Record<string, unknown>>)?.onQueryChange?.();
    }
  };

  const handleClose = (e: Record<string, unknown>, reason: string): void => {
    if (reason !== 'backdropClick') {
      setOpenModal(false);
      getList();
      setModalData(undefined);
    }
  };

  // eslint-disable-next-line
  const constructUrl = (query: Query<object>): string => {
    const qsp: string[] = [];
    const url = '/signatureReviewList';
    if (query.filters) {
      query.filters.forEach((f) => {
        qsp.push(`${f.column.field as string}=${f.value as string}`);
      });
    }
    qsp.push(`per_page=${query.pageSize}`);
    qsp.push(`page=${query.page + 1}`);

    if (query.orderBy && query.orderDirection) {
      qsp.push(`order=${query.orderBy.field as string}`);
      qsp.push(`orderDirection=${query.orderDirection}`);
    }

    const queryStringParams = qsp.join('&');
    if (queryStringParams.length > 0) {
      return `${url}?${queryStringParams}`;
    }
    return url;
  };

  const columns = [
    {
      title: 'Matter Reference', field: 'matter_reference',
    },
    {
      title: 'Status', field: 'status',
    },
    {
      title: 'Signed By', field: 'signed_by',
    },
    {
      title: 'Signed On', field: 'signed_at',
    },
    {
      title: 'Firm Name', field: 'firm_name',
    },
    {
      title: 'Oliver Description', field: 'template_reference',
    },
  ];

  const handleClick = (rowData: SignatureReviewData): void => {
    setModalData(rowData);
    handleOpen();
  };

  const actions: Action[] = [{
    icon: () => <EditIcon />,
    tooltip: 'View',
    onClick: (_e, rowData) => handleClick(rowData as SignatureReviewData),
  }];

  const renderModal = (): JSX.Element => {
    if (modalData) {
      return (<SignatureReviewModal open={openModal} handleClose={handleClose} modalData={modalData} />);
    }
    return <></>;
  };

  return (
    <Container maxWidth={false}>
      <Header text="Signature Review" period />
      <div>
        {renderModal()}
        <OliMaterialTable
          tableRef={tableRef}
          columns={columns}
          data={(query) => new Promise((resolve) => {
            const url = constructUrl(query);
            OliAxios.get(url, { ...getConfig(token || '') })
              .then((response) => response.data as unknown as MTQuery)
              .then((result) => {
                resolve({
                  data: result.data,
                  page: parseInt(result.page, 10) - 1,
                  totalCount: parseInt(result.total, 10),
                });
              })
              .catch((e) => console.error(e));
          })}
          pagesize={20}
          actions={actions}
          selectable={false}
          filter
        />
      </div>
    </Container>
  );
};

export default SignatureReviewList;
