const useStyles = {
  button: {
    color: 'white',
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: '#3e3842',
  },
  sso: {
    paddingBottom: '24px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  ssoButton: {
    width: '100%',
  },
  logo: {
    paddingTop: '10px',
    paddingLeft: '8px',
  },
};

export { useStyles };
