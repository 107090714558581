import React, {
  useContext, useEffect, useState,
} from 'react';

import {
  useParams, useNavigate,
} from 'react-router-dom';

import {
  Box, Button, CircularProgress, DialogActions, DialogContent,
} from '@mui/material';
import {
  DocPrepProps,
} from './DocPrep.types';
import {
  getPresignedUrl,
  approvePDF,
} from './DocPrepWorkflow.api';
import UserContext from '../../context/UserContext';

const DocPrepFinalize: React.FunctionComponent<DocPrepProps> = ({
  loading, setLoading, docPrepData, setActiveStep,
}) => {
  const token = useContext(UserContext)?.token;

  const navigate = useNavigate();
  const {
    filename,
  } = useParams();

  const [presignedUrl, setPresignedURL] = useState<string>();

  const fetchPresignedUrl = async (): Promise<void> => {
    if (!token) return;

    const resp = await getPresignedUrl(token, process.env.REACT_APP_MERGED_TEMP_BUCKET, filename);

    if (!resp) return;

    setPresignedURL(resp?.data.url);
  };

  useEffect(() => {
    setActiveStep(3);

    fetchPresignedUrl();
  }, [filename]);

  const handleFinish = async (): Promise<void> => {
    const params = {
      s3key: filename,
      matterUuid: docPrepData.doc?.matter_uuid,
      tags: docPrepData.doc?.client_tags,
      oliver_tag: docPrepData.doc?.oliver_tag,
    };
    setLoading(true);
    await approvePDF(token, params);
    setLoading(false);
    navigate('/doc-prep');
  };

  const renderPDFPreview = (): JSX.Element => {
    if (presignedUrl) {
      return (
        <div>
          <iframe
            style={{
              width: '100%', minHeight: '750px',
            }}
            title="preview"
            src={presignedUrl}
          />
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <DialogContent>
        <br />
        <Box
          alignItems="flex-end"
          component="form"
          noValidate
          sx={{
            width: '100%',
          }}
        >
          {renderPDFPreview()}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={() => navigate(-1)}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button variant="contained" color="secondary" onClick={handleFinish} disabled={loading}>
          {loading && (
            <CircularProgress
              size={20}
              sx={{
                marginRight: '0.5rem',
              }}
            />
          )}
          Finish
        </Button>
      </DialogActions>
    </>
  );
};

export default DocPrepFinalize;
