const columns = [
  {
    title: 'Matter Reference', field: 'matter_reference',
  },
  {
    title: 'State', field: 'state_prov_code',
  },
  {
    title: 'Customer Name', field: 'customer_name',
  },
  {
    title: 'Date Created', field: 'created_on',
  },
  {
    title: 'Law Firm', field: 'law_firm_name',
  },
];

export default columns;
