import React, {
  useState, useEffect,
} from 'react';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import DoughnutChart from 'components/charts/DoughnutChart';
import {
  DonutChartProps, Counts,
} from './DonutChart.types';

const DonutChart: React.FunctionComponent<DonutChartProps> = (props) => {
  const {
    firmname, period, chartType,
  } = props;
  const [chartData, setChartData] = useState([0, 0]);

  let url = '';
  let chartLabels:Array<string> = [];
  if (chartType === 'io') {
    url = '/metrics/inbound-outbound';
    chartLabels = ['Inbound', 'Outbound'];
  } else if (chartType === 'pe') {
    url = '/metrics/processed-errored';
    chartLabels = ['Processed', 'Errored'];
  } else if (chartType === 'to') {
    url = '/metrics/total-outstanding';
    chartLabels = ['Total', 'Outstanding'];
  }
  const {
    loading, data,
  } = useAxiosGet<Counts>(url, []);

  useEffect(() => {
    if (data) {
      const temp = data.find((element) => element.firm === firmname);
      if (temp) {
        const curr = temp[period];
        setChartData([curr.green, curr.red]);
      } else {
        setChartData([0, 0]);
      }
    }
  }, [data, period, firmname]);

  return <DoughnutChart chartValues={chartData} chartLabels={chartLabels} loading={loading} />;
};

export default DonutChart;
