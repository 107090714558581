import { Grid } from '@mui/material';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import React from 'react';
import { useParams } from 'react-router-dom';

const AccountDetails: React.FunctionComponent = () => {
  type AccountDetails = {
    account_uuid: string
    charge_off_amount: string
    charge_off_date: string
    delinquency_date: string
    matter_uuid: string
    name: string
    open_date: string
    terms_and_conditions: string
    client_product_code: string
    cycle_date: string
    closure_date: string
    last_cash_advance_date: string
    last_cash_advance_amount: string
    last_merchandise_purchase_date: string
    last_merchandise_purchase_amount: string
  };
  const { matterUUID } = useParams();
  const {
    data, loading,
  } = useAxiosGet<AccountDetails>(`/getAccountDetails?matterUuid=${matterUUID}`, []);

  console.info(data);

  const columns = [
    {
      title: 'Key', field: 'key',
    },
    {
      title: 'Value', field: 'value',
    },
  ];

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={6}>
        <OliMaterialTable
          title="Account Details"
          columns={columns}
          pagesize={20}
          search
          data={[{
            id: 'Creditor', key: 'Creditor: ', value: data?.name,
          }, {
            id: '3rd Party Rep Authorized User', key: '3rd Party Rep Authorized User: ', value: '',
          }, {
            id: 'Product Type', key: 'Product Type: ', value: data?.client_product_code,
          }, {
            id: 'Terms and Conditions', key: 'Terms and Conditions: ', value: data?.terms_and_conditions,
          }, {
            id: 'Account Open Date', key: 'Account Open Date: ', value: data?.open_date,
          }, {
            id: 'Previous Account Numbers', key: 'Previous Account Numbers: ', value: '',
          }, {
            id: 'First Date of Delinquency', key: 'First Date of Delinquency: ', value: data?.delinquency_date,
          }, {
            id: 'Charge Off Date', key: 'Charge Off Date: ', value: data?.charge_off_date,
          }, {
            id: 'Charge Off Balance', key: 'Charge Off Balance: ', value: data?.charge_off_amount,
          }, {
            id: 'Closure Date', key: 'Closure Date: ', value: data?.closure_date,
          }, {
            id: 'Cycle Date', key: 'Cycle Date: ', value: data?.cycle_date,
          }, {
            id: 'Last Cash Advance', key: 'Last Cash Advance: ', value: data?.last_cash_advance_amount,
          }, {
            id: 'Last Cash Date', key: 'Last Cash Date: ', value: data?.last_cash_advance_date,
          }, {
            id: 'Last Merchandise Purchase', key: 'Last Merchandise Purchase: ', value: data?.last_merchandise_purchase_amount,
          }, {
            id: 'Last Merchandise Purchase Date', key: 'Last Merchandise Purchase Date: ', value: data?.last_merchandise_purchase_date,
          }]}
          selectable={false}
          header={false}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default AccountDetails;
