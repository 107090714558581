import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import {
  useParams, useNavigate,
} from 'react-router-dom';
import {
  Box, Button, DialogActions, DialogContent,
} from '@mui/material';

import UserContext from 'context/UserContext';
import {
  DocPrepPreviewProps,
} from './DocPrep.types';
import {
  getPresignedUrl,
} from './DocPrepWorkflow.api';

const DocPrepPreview: React.FunctionComponent<DocPrepPreviewProps> = ({
  loading, setActiveStep,
}) => {
  const token = useContext(UserContext)?.token;
  const navigate = useNavigate();
  const {
    docId,
    filename,
  } = useParams();

  const [presignedUrl, setPresignedURL] = useState<string>();

  const fetchPresignedUrl = async (): Promise<void> => {
    if (!token) return;

    const resp = await getPresignedUrl(token, process.env.REACT_APP_MERGED_TEMP_BUCKET, filename);

    if (!resp) return;

    setPresignedURL(resp?.data.url);
  };

  useEffect(() => {
    setActiveStep(1);

    fetchPresignedUrl();
  }, [filename]);

  const renderPDFPreview = (): JSX.Element => {
    if (presignedUrl) { // && !showDocusign) {
      return (
        <div>
          <iframe
            style={{
              width: '100%', minHeight: '750px',
            }}
            title="preview"
            src={presignedUrl}
          />
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <DialogContent>
        <br />
        <Box
          alignItems="flex-end"
          component="form"
          noValidate
          sx={{
            width: '100%',
          }}
        >
          {renderPDFPreview()}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={() => navigate(-1)}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={() => navigate(`/doc-prep/packets/${docId}/add-exhibits/${encodeURIComponent(filename)}`)}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
};

export default DocPrepPreview;
