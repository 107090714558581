import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Skeleton from '@mui/material/Skeleton';
import {
  IDoughnutChartProps, IData,
} from './DoughnutChart.types';

const DoughnutChart: React.FunctionComponent<IDoughnutChartProps> = (props) => {
  const {
    chartValues, chartLabels, loading,
  } = props;

  const donutData:IData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Total v Outstanding',
        data: chartValues,
        backgroundColor: [
          '#8EA433',
          '#C75236',
        ],
        borderColor: [
          '#8EA433',
          '#C75236',
        ],
        borderWidth: 1,
      },
    ],
  };

  const renderChart = ():JSX.Element => {
    if (loading || donutData.datasets[0].data.every((v) => [0, 0].includes(v))) {
      return (
        <Skeleton
          variant="circular"
          width={240}
          height={240}
          style={{
            marginTop: '33px', marginLeft: '15px',
          }}
        />
      );
    }
    return <Doughnut data={donutData} />;
  };

  return (
    <span>
      { renderChart() }
    </span>
  );
};

export default DoughnutChart;
