import React, { useContext } from 'react';
import UserContext from 'context/UserContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import MergeType from '@mui/icons-material/MergeType';
import moment from 'moment';
import { AxiosStatic } from 'axios';
import { Action } from 'components/OliMaterialTable/OliMaterialTable.types';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import {
  downloadFile, deleteFile, downloadMultipleFile, combineAndDownload,
} from './List.api';
import {
  Data, FileTransferListProps, DownloadParams, DeleteParams,
} from '../FileTransfer.types';
import analytics from '../../../Analytics';

const List: React.FunctionComponent<FileTransferListProps> = (props) => {
  const {
    data, fileNo, getFileData,
  } = props;

  const modifyData = (filedata: Data[] = []): Data[] => filedata.map((element) => ({
    ...element, uploaded: moment(element.uploaded).format('MM/DD/YYYY'),
  }));

  const token = useContext(UserContext)?.token;
  const handleView = async (filedata: Data[]): Promise<void> => {
    const promises: Promise<AxiosStatic | undefined>[] = [];
    filedata.forEach((d) => {
      const params: DownloadParams = {
        path: d.path, fileID: d.id, isDownload: false,
      };
      promises.push(downloadFile(token, params));
    });
    await Promise.all(promises);
  };

  const handleDownload = async (filedata: Data[]): Promise<void> => {
    const downloadParams: DownloadParams[] = [];
    filedata.forEach((d) => {
      const params: DownloadParams = {
        path: d.path, fileID: d.id, isDownload: true,
      };
      downloadParams.push(params);
    });
    if (downloadParams.length > 1) {
      await downloadMultipleFile(token, downloadParams);
    } else {
      const params: DownloadParams = {
        path: filedata[0].path, fileID: filedata[0].id, isDownload: true,
      };
      await downloadFile(token, params);

      analytics.track('file-transfer-download');
    }
  };

  const handleCombineAndDownload = async (filedata: Data[]):Promise<void> => {
    const inputPdfs = filedata.map((fd) => fd.path);
    await combineAndDownload(token, { inputPdfs });
  };

  const handleDelete = async (fileData: Data[]): Promise<void> => {
    const promises: Promise<AxiosStatic | undefined>[] = [];
    fileData.forEach((d) => {
      const params: DeleteParams = {
        fileID: d.id, file_no: fileNo,
      };
      promises.push(deleteFile(token, params));
    });
    await Promise.all(promises);
    getFileData();
  };

  const columns = [
    {
      title: 'Tags',
      field: 'description',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
    {
      title: 'Name',
      field: 'name',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
    {
      title: 'Uploaded On',
      field: 'uploaded',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
    {
      title: 'Creditor Create Date',
      field: 'sor_creation_date',
      headerStyle: {
        fontWeight: 'bold',
      },
    },
  ];

  const actions: Action[] = [
    {
      icon: () => <VisibilityIcon />,
      tooltip: 'View File',
      onClick: (_e, rowData) => handleView(rowData as Data[]),
    },
    {
      icon: () => <DownloadIcon />,
      tooltip: 'Download File',
      onClick: (_e, rowData) => handleDownload(rowData as Data[]),
    },
    {
      icon: () => <MergeType style={{ transform: 'scaleY(-1)' }} />,
      tooltip: 'Combine and Download',
      onClick: (_e, rowData) => handleCombineAndDownload(rowData as Data[]),
    },
    {
      icon: () => <DeleteIcon />,
      tooltip: 'Delete File',
      onClick: (_e, rowData) => handleDelete(rowData as Data[]),
    },
  ];

  return (
    <OliMaterialTable
      actions={actions}
      columns={columns}
      loading={false}
      data={modifyData(data)}
      pagesize={5}
      search
      filter
    />
  );
};

export default List;
