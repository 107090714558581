import { AxiosStatic } from 'axios';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  DownloadResponse, DownloadParams, DeleteParams, ConcatenatePDFResponse,
} from '../FileTransfer.types';

async function combineAndDownload(token: string | undefined, params: unknown): Promise<AxiosStatic | undefined> {
  if (token) {
    const config = getConfig(token);
    const url = '/concatenatepdf';
    const response: ConcatenatePDFResponse = await OliAxios.get(url, {
      ...config, params,
    });
    console.info(response);
    const downloadURL = response.data;
    const link = document.createElement('a');
    link.href = downloadURL;
    link.click();
  }
  return undefined;
}

async function downloadFile(token: string | undefined, params: DownloadParams, retryCount = 0): Promise<AxiosStatic | undefined> {
  if (token) {
    let retry = retryCount;
    const config = getConfig(token);
    const url = '/fileTransfer/download';
    try {
      const response: DownloadResponse = await OliAxios.get(url, {
        ...config, params,
      });

      const { downloadURL } = response.data;
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = params.path;
      if (!params.isDownload) {
        link.target = '_blank';
      }
      link.click();
    } catch (e) {
      if (retry < 2) {
        downloadFile(token, params, retry += 1);
      }
    }
  }
  return undefined;
}

async function downloadMultipleFile(token: string | undefined, downloadParams: DownloadParams[], retryCount = 0): Promise<void> {
  if (token) {
    let retry = retryCount;
    const config = getConfig(token);
    const url = '/fileTransfer/download';
    const currentTimestamp = moment().format('YYYYMMDD_HHmmSS');
    const folderName = `${currentTimestamp}_oliver_download`;
    const zipName = `${folderName}.zip`;
    try {
      const zip = new JSZip();
      const folder = zip.folder(folderName);
      const results = [];
      for (let index = 0; index < downloadParams.length; index++) {
        const params = downloadParams[index];
        try {
          results.push(OliAxios.get(url, {
            ...config, params,
          }));
        } catch (e) {
          console.error(e);
        }
      }
      const response: DownloadResponse[] = await Promise.all(results);
      const results1 = [];
      for (let index = 0; index < response.length; index++) {
        const {
          downloadURL, key,
        } = response[index].data;
        const fileName = key?.substring(key.lastIndexOf('/') + 1);
        results1.push(fetch(downloadURL).then((response1) => response1.arrayBuffer()).then((data) => {
          folder?.file(fileName, data);
        }));
      }
      await Promise.all(results1);

      await zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, zipName);
      });
    } catch (e) {
      if (retry < 2) {
        downloadMultipleFile(token, downloadParams, retry += 1);
      }
    }
  }
}

async function deleteFile(token: string | undefined, params: DeleteParams, retryCount = 0): Promise<AxiosStatic | undefined> {
  if (token) {
    let retry = retryCount;
    const config = getConfig(token);
    const url = '/fileTransfer/delete';
    try {
      return await OliAxios.get(url, {
        ...config, params,
      });
    } catch (e) {
      if (retry < 2) {
        deleteFile(token, params, retry += 1);
      }
    }
  }
  return undefined;
}

export {
  deleteFile, downloadFile, downloadMultipleFile, combineAndDownload,
};
