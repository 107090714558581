import {
  embedDashboard,
  EmbedDashboardParams,
} from '@superset-ui/embedded-sdk';

import {
  useParams,
} from 'react-router-dom';

import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import UserContext from 'context/UserContext';
import {
  Container,
} from '@mui/material';

import Header from 'components/Header/Header';

import { getDashboard } from '../Dashboards/Dashboards.api';
import { GetDashboard } from '../Dashboards/Dashboards.types';

const Superset: React.FunctionComponent = () => {
  const {
    report,
  } = useParams();

  const iframeRef = React.createRef<HTMLIFrameElement>();
  const token = useContext(UserContext)?.token;
  const [dashboard, setDashboard] = useState<GetDashboard | null>();

  useEffect(() => {
    getDashboard(token, report).then((dash) => {
      setDashboard(dash);
    });
  }, [report]);

  useEffect(() => {
    if (dashboard) {
      embedDashboard({
        id: dashboard.embeddedUuid,
        supersetDomain: process.env.REACT_APP_SUPERSET_V2_URL,
        mountPoint: iframeRef.current as HTMLIFrameElement,
        fetchGuestToken: () => dashboard.token,
        dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional), urlParams (optional)
          hideTitle: true,
          filters: {
            expanded: true,
          },
        },
        // optional additional iframe sandbox attributes
        iframeSandboxExtras: ['allow-top-navigation', 'allow-popups-to-escape-sandbox'],
      } as EmbedDashboardParams);
    }
  }, [dashboard]);

  return (
    <Container maxWidth="xl">
      <Header text="" />
      <div data-testid="iframe-div" className="embedded-superset" ref={iframeRef} />
    </Container>
  );
};

export default Superset;
