import React, {
  useEffect, useContext, useState,
} from 'react';
import {
  Grid,
} from '@mui/material';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UserContext from 'context/UserContext';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import columns from './SearchResultsColumns';
import { Action } from '../../OliMaterialTable/OliMaterialTable.types';
import { SearchResult } from './SearchResults.types';

const SearchResults: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { term } = useParams();
  const [data, setData] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const token = useContext(UserContext)?.token;

  const viewMatter = (uuid:string):void => {
    navigate(`/matter/${uuid}`);
  };

  const actions:Action[] = [{
    icon: () => <VisibilityIcon />,
    tooltip: 'View Matter',
    onClick: (_e, rowData) => viewMatter((rowData as SearchResult).matter_uuid), // eslint-disable-line
  }];

  const handleSuccess = (result:SearchResult[]):void => {
    setData(result);
  };

  const getData = async ():Promise<void> => {
    if (token) {
      setLoading(true);
      const config = getConfig(token);
      const url = '/quickSearch';
      const params = { term };
      await OliAxios.get(url, {
        ...config, params,
      }).then((result) => handleSuccess(result.data));
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [term, token]);

  return (
    <>
      <br />
      <Grid container>
        <Grid item xs={1} />
        <Grid item container xs={10}>
          <Grid container justifyContent="space-evenly" alignItems="center">
            <Grid item xs>
              <br />
              <OliMaterialTable
                data={data}
                columns={columns}
                loading={loading}
                search
                filter
                title={`Search Results: "${term}"`}
                pagesize={10}
                actions={actions}
                selectable={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default SearchResults;
