import React, {
  useContext,
} from 'react';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import UserContext from 'context/UserContext';
import { useParams } from 'react-router-dom';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import { MTQuery } from 'components/OliMaterialTable/OliMaterialTable.types';
import {
  Query,
} from '@material-table/core';

const TransactionList: React.FunctionComponent = () => {
  const tableRef = React.useRef();
  const { matterUUID } = useParams();
  const token = useContext(UserContext)?.token;

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  type TransactionData = {
    total_amount: string
  };
  const columns = [
    {
      title: 'Posted Date', field: 'post_date',
    },
    {
      title: 'Effective Date', field: 'transaction_date',
    },
    {
      title: 'Transaction Type', field: 'transaction_type',
    },
    {
      title: 'Amount',
      field: 'total_amount',
      render: (rowData: TransactionData) => (
        <div>{USDollar.format(rowData.total_amount as unknown as number)}</div>
      ),
    },
    {
      title: 'Payment Source', field: 'event_name',
    },
  ];

  // eslint-disable-next-line @typescript-eslint/ban-types
  const constructUrl = (query: Query<object>): string => {
    const qsp: string[] = [];
    if (matterUUID) {
      qsp.push(`matterUuid=${matterUUID}`);
    }
    if (query.filters) {
      query.filters.forEach((f) => {
        qsp.push(`${f.column.field as string}=${f.value as string}`);
      });
    }

    if (query.orderBy && query.orderDirection) {
      qsp.push(`order=${query.orderBy.field as string}`);
      qsp.push(`orderDirection=${query.orderDirection}`);
    }

    qsp.push(`per_page=${query.pageSize}`);
    qsp.push(`page=${query.page + 1}`);

    const queryStringParams = qsp.join('&');
    if (queryStringParams.length > 0) {
      return `?${queryStringParams}`;
    }
    return '';
  };

  return (
    <div>
      <OliMaterialTable
        tableRef={tableRef}
        data={(query) => new Promise((resolve) => {
          const url = `/getTransactionsList${constructUrl(query)}`;
          OliAxios.get(url, { ...getConfig(token || '') })
            .then((response) => response.data as unknown as MTQuery)
            .then((result) => {
              resolve({
                data: result.data,
                page: parseInt(result.page, 10) - 1,
                totalCount: parseInt(result.total, 10),
              });
            })
            .catch((e) => console.error(e));
        })}
        title="Transactions"
        pagesize={10}
        columns={columns}
        filter
      />
    </div>
  );
};

export default TransactionList;
