import React from 'react';
import oliver_white from '../../oliver_white.svg';
import oliver from '../../oliver.svg';
import { useStyles } from './Logo.jss';
import { ILogoProps } from './Logo.types';

const Logo: React.FunctionComponent<ILogoProps> = (props) => {
  const {
    variant, router,
  } = props;
  const image = variant === 'black' ? oliver : oliver_white;
  const classes = useStyles;
  const handleClick = ():void => {
    if (router) {
      window.open('/', '_self');
    } else {
      window.open('https://olivertechnology.com/', '_blank');
    }
  };
  return (
    <>
      <img src={image} alt="logo" style={classes.logo} onClick={handleClick} aria-hidden="true" />
    </>
  );
};

export default Logo;
