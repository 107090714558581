const columns = [
  {
    title: 'File Tag', field: 'tag',
  },
  {
    title: 'Create Date', field: 'uploaded_on',
  },
  {
    title: 'File Name', field: 'name',
  },
  {
    title: 'File Number', field: 'number',
  },
  {
    title: 'Accessed', field: 'accessed',
  },
  {
    title: 'Creditor Create Date', field: 'sor_creation_date',
  },
];

export default columns;
