const useStyles = {
  icon: {
    color: 'rgb(61,55,65,.25)',
  },
  sortButton: {
    marginRight: '15px',
  },
  buttonGroup: {
    marginLeft: '12px',
  },
  headerItem: {
    marginLeft: '12px',
  },
  datePicker: {
    maxWidth: '140px',
    marginRight: '35px',
    marginBottom: '30px',
  },
  uploadButton: {
    marginRight: '27px',
  },
};

export { useStyles };
