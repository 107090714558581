import React, {
  useEffect, useState, useContext,
} from 'react';
import {
  useParams, Routes, Route,
} from 'react-router-dom';
import {
  Box, Stepper, Step, StepLabel,
} from '@mui/material';
import UserContext from 'context/UserContext';
import {
  DocPrepData,
} from './DocPrep.types';
import {
  getDoc,
} from './DocPrepWorkflow.api';
import DocPrepDataInput from './DocPrepDataInput';
import DocPrepPreview from './DocPrepPreview';
import DocPrepExhibits from './DocPrepExhibits';
import DocPrepFinalize from './DocPrepFinalize';

const steps = ['Data Input', 'Document Preview', 'Add Exhibits', 'Final Review'];

// eslint-disable-next-line max-lines-per-function
const DocPrepWorkflow: React.FunctionComponent = () => {
  const token = useContext(UserContext)?.token;

  const { docId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [docPrepData, setDocPrepData] = useState<DocPrepData>({
    doc: null,
    matter_uuid: '',
  });

  const [activeStep, setActiveStep] = useState<number>(0);

  // Convenience method for setting properties of docPrepData
  const updateDocPrepData = (updatedFields: Partial<DocPrepData>): void => {
    setDocPrepData((prevData) => ({
      ...prevData,
      ...updatedFields,
    }));
  };

  useEffect(() => {
    if (!token) return;

    const getDocPrepData = async (): Promise<void> => {
      setLoading(true);
      const result = await getDoc(token, docId);
      if (result) {
        const matterUuid = result?.data.data[0].matter_uuid;
        updateDocPrepData({
          matter_uuid: matterUuid,
          doc: result?.data.data[0],
        });
      }
      setLoading(false);
    };
    getDocPrepData();
  }, [docId]);

  const renderDocPrepStepper = (): JSX.Element => (
    <>
      <Box sx={{
        width: '80%',
        margin: '0 auto',
      }}
      >
        <Stepper activeStep={activeStep} sx={{ marginBottom: 3 }} alternativeLabel>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step
                key={label}
                {...stepProps}
              >
                <StepLabel
                  StepIconProps={{
                    sx: {
                      '&.Mui-completed': {
                        color: '#ca304c',
                      },
                    },
                  }}
                  sx={{
                    '& .MuiStepLabel-label': {
                      fontSize: '1.25rem',
                      fontWeight: 'bold',
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Routes>
          <Route
            path="/"
            element={(
              <DocPrepDataInput
                setActiveStep={setActiveStep}
                loading={loading}
                setLoading={setLoading}
                docPrepData={docPrepData}
              />
            )}
          />
          <Route
            path="/preview/:filename"
            element={(
              <DocPrepPreview
                setActiveStep={setActiveStep}
                loading={loading}
              />
            )}
          />
          <Route
            path="/add-exhibits/:filename"
            element={(
              <DocPrepExhibits
                setActiveStep={setActiveStep}
                loading={loading}
                setLoading={setLoading}
                docPrepData={docPrepData}
              />
            )}
          />
          <Route
            path="/finalize/:filename"
            element={(
              <DocPrepFinalize
                setActiveStep={setActiveStep}
                loading={loading}
                setLoading={setLoading}
                docPrepData={docPrepData}
              />
            )}
          />
        </Routes>
      </Box>
    </>
  );

  return renderDocPrepStepper();
};

export default DocPrepWorkflow;
