const useStyles = {
  root: {
    width: '100%',
    backgroundColor: 'theme.palette.background.paper',
    overflow: 'auto',
    maxHeight: '401px',
  },
  avatarred: {
    backgroundColor: '#C75236',
    borderRadius: '10px 0px 0px 10px',
  },
  avatargreen: {
    backgroundColor: '#8EA433',
    borderRadius: '10px 0px 0px 10px',
  },
  listitemtext: {
    color: '#3E3842',
  },
  icon: {
    color: 'rgb(61,55,65,.25)',
  },
  alert: {
    margin: '15px',
  },
};

export { useStyles };
