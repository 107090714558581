import axios, { AxiosError } from 'axios';
import Auth from '@aws-amplify/auth';
import awsconfig from '../../aws-exports';

const OliAxios = axios.create({
  baseURL: awsconfig.aws_cloud_logic_custom[0].endpoint,
});

OliAxios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      Auth.signOut();
      sessionStorage.clear();
      localStorage.clear();
      window.location.reload();
    }
    Promise.reject(error);
  }
);

export default OliAxios;
