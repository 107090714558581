/// <reference types="@types/segment-analytics" />
import ls from '@livesession/sdk';
import {
  AnalyticsBrowser, EventProperties,
} from '@segment/analytics-next';
import setupAmpSRSegmentWrapper from '@amplitude/segment-session-replay-wrapper';

let analytics: AnalyticsBrowser;
let userIdentified = false;

// LiveSession
const load = (): void => {
  if (process.env.REACT_APP_LS_TRACK_ID != null) {
    ls.init(process.env.REACT_APP_LS_TRACK_ID);
    ls.newPageView();
  }

  if (process.env.REACT_APP_SEGMENT_WRITE_KEY != null && !userIdentified) {
    // eslint-disable-next-line no-console
    analytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY });

    if (process.env.REACT_APP_AMP_API_KEY != null) {
      setupAmpSRSegmentWrapper({
        segmentInstance: analytics,
        amplitudeApiKey: process.env.REACT_APP_AMP_API_KEY,
        sessionReplayOptions: {
          sampleRate: 1,
        },
      });
    }
  }
};

const identify = (email: string, identifier: string): void => {
  if (analytics && !userIdentified) {
    analytics.identify(email, { identifier });
    userIdentified = true;
  }
};

const page = (): void => {
  if (analytics) {
    analytics.page();
  }
};

const track = (name: string, properties: EventProperties = {}): void => {
  if (analytics) {
    analytics.track(name, properties);
  }
};

export default {
  load,
  identify,
  page,
  track,
};
