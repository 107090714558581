import React, { useContext } from 'react';
import {
  Container, Grid,
} from '@mui/material';
import FileManagement from 'components/FileManagement/FileManagement';
import Graphs from 'components/Graphs/Graphs';
import UserContext from 'context/UserContext';
import ReportDownload from './ReportDownload/ReportDownload';
import SplashText from './SplashText/SplashText';

const Home: React.FunctionComponent = () => {
  const type = useContext(UserContext)?.type;
  const token = useContext(UserContext)?.token;

  const renderReportDownload = ():JSX.Element => {
    if (type === 'CREDITOR') return <ReportDownload />;
    return <div />;
  };

  const renderGraphs = ():JSX.Element => {
    const allowed = ['FIRM', 'CREDITOR'];
    if (allowed.indexOf(type || '') !== -1) return <Graphs />;
    return <div />;
  };

  const renderHome = ():JSX.Element => {
    if (!token) {
      return <div />;
    }
    return (
      <>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item md={7}>
              <SplashText />
              { renderReportDownload() }
              { renderGraphs() }
            </Grid>
            <Grid item md={1} />
            <Grid item md={4}>
              <FileManagement />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };

  return (<>{renderHome()}</>);
};

export default Home;
