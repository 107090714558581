import { createContext } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

interface IUserContext {
  user: CognitoUser | undefined
  token: string | undefined
  type: string | undefined
  name: string
  supersetUser: string | undefined
  userName: string | undefined
}

const UserContext = createContext<IUserContext | undefined>(undefined);
export default UserContext;
