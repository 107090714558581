import React, {
  useState, useContext, useEffect,
} from 'react';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import { Action } from 'components/OliMaterialTable/OliMaterialTable.types';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UserContext from 'context/UserContext';
import { useParams } from 'react-router-dom';
import {
  getList, postAttorney,
} from './Attorney.api';
import {
  AttorneyListData, AttorneyCustomer,
} from './Attorney.types';
import AttorneyModal from './AttorneyModal';

const AttorneyList: React.FunctionComponent = () => {
  const { matterUUID } = useParams();
  const token = useContext(UserContext)?.token;
  const [tableData, setTableData] = useState<AttorneyListData[]>([]);
  const [customerData, setCustomerData] = useState<AttorneyCustomer[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState<AttorneyListData>();
  const [openModal, setOpenModal] = useState(false);

  const getListData = async ():Promise<void> => {
    setLoading(true);
    const data = await getList(token, matterUUID);
    if (data) {
      setTableData(data.attorneys);
      setCustomerData(data.customers);
    }
    setLoading(false);
  };

  const handleOpen = ():void => setOpenModal(true);
  const handleClose = (e:Record<string, unknown>, reason:string):void => {
    if (reason !== 'backdropClick') {
      setModalData(undefined);
      setOpenModal(false);
      getListData();
    }
  };

  const handleClick = (rowData:AttorneyListData):void => {
    setModalData(rowData);
    handleOpen();
  };

  const deleteAttorney = async (rowData:AttorneyListData):Promise<void> => {
    setLoading(true);
    await postAttorney(token, 'delete', rowData);
    getListData();
  };

  useEffect(() => {
    if (!token || !matterUUID) return;
    getListData();
  }, [token, matterUUID]);

  const columns = [
    {
      title: 'Name', field: 'name',
    },
    {
      title: 'Firm Name', field: 'firm_name',
    },
    {
      title: 'Bar Number', field: 'bar_number',
    },
    {
      title: 'Email', field: 'email',
    },
    {
      title: 'Phone', field: 'phone',
    },
  ];

  const actions:Action[] = [
    {
      icon: () => <EditIcon />,
      tooltip: 'View Attorney',
      onClick: (_e, rowData) => handleClick(rowData as AttorneyListData),
    },
    {
      icon: () => <DeleteIcon />,
      tooltip: 'Delete Attorney',
      onClick: (_e, rowData) => deleteAttorney(rowData as AttorneyListData),
    },
    {
      icon: () => <AddIcon />,
      tooltip: 'Add Attorney',
      isFreeAction: true,
      onClick: (_e, rowData) => handleClick(rowData as AttorneyListData),
    },
  ];

  const renderModal = ():JSX.Element => {
    if (modalData) {
      return (<AttorneyModal open={openModal} handleClose={handleClose} modalData={modalData} customerData={customerData} />);
    }
    return <></>;
  };

  return (
    <div>
      {renderModal()}
      <OliMaterialTable
        title="Attorney"
        actions={actions}
        columns={columns}
        loading={loading}
        data={tableData}
        pagesize={10}
        search
        selectable={false}
      />
    </div>
  );
};

export default AttorneyList;
