const useStyles = {
  iconText: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: '10px',
  },
  formControl: {
    minWidth: 359,
  },
  typoTitle: {
    fontSize: 14,
  },
  typoData: {
    mb: 1.5,
  },
  pointer: {
    cursor: 'pointer',
  },
};

export { useStyles };
