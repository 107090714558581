import React, {
  useState, useContext,
} from 'react';
import {
  Grid, ButtonGroup, Button, Container, FormControl, InputLabel, MenuItem, CircularProgress,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import UserContext from 'context/UserContext';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import { PeriodType } from './DonutChart.types';
import DonutChart from './DonutChart';
import { DonutFilterApiData } from './Graphs.types';

const Graphs: React.FunctionComponent = () => {
  const [period, setPeriod] = useState<PeriodType>('DAY' as PeriodType);
  const [firmname, setFirmname] = useState('all');
  const type = useContext(UserContext)?.type;
  const url = '/util/donut-filters';

  const {
    loading, data,
  } = useAxiosGet<DonutFilterApiData[]>(url, [url]);

  const handleChange = (event: SelectChangeEvent):void => {
    setFirmname(event.target.value);
  };

  const handleClick = (value:string):void => {
    if (period === value) return;
    setPeriod(value as PeriodType);
  };

  const renderFirmSelect = ():JSX.Element => {
    if (loading) {
      return <CircularProgress color="secondary" />;
    }
    if (type === 'CREDITOR' && data && data?.length > 1) {
      return (
        <Grid item>
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">Firm</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={firmname}
              onChange={handleChange}
            >
              <MenuItem value="all">All</MenuItem>
              {data.map((firm) => <MenuItem key={firm.key} value={firm.key}>{firm.key}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      );
    }
    return <div />;
  };

  return (
    <Container>
      <Grid container sx={{ paddingTop: '25px' }}>
        <Grid item md={4}>
          <DonutChart firmname={firmname} period={period} chartType="pe" />
        </Grid>
        <Grid item md={4}>
          <DonutChart firmname={firmname} period={period} chartType="io" />
        </Grid>
        <Grid item md={4}>
          <DonutChart firmname={firmname} period={period} chartType="to" />
        </Grid>
      </Grid>
      <br />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group" style={{ marginTop: '12px' }}>
            <Button color={period === 'DAY' ? 'secondary' : 'primary'} onClick={() => handleClick('DAY')}>Day</Button>
            <Button color={period === 'WEEK' ? 'secondary' : 'primary'} onClick={() => handleClick('WEEK')}>Week</Button>
            <Button color={period === 'MONTH' ? 'secondary' : 'primary'} onClick={() => handleClick('MONTH')}>Month</Button>
          </ButtonGroup>
        </Grid>
        { renderFirmSelect() }
      </Grid>
    </Container>
  );
};

export default Graphs;
