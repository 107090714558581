import React, {
  useState, useContext,
} from 'react';
import {
  Button, FormControl, InputLabel, MenuItem, Grid, CircularProgress, Alert,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import UserContext from 'context/UserContext';
import { useStyles } from './ReportDownload.jss';
import { IRow } from './ReportDownload.types';
import { downloadFile } from '../FileList/FileList.api';

const ReportDownload: React.FunctionComponent = () => {
  const classes = useStyles;
  const [filepath, setFilepath] = useState('');
  const token = useContext(UserContext)?.token;

  const {
    loading, data, error,
  } = useAxiosGet<IRow[]>('/files/report-list');

  const handleChange = (event: SelectChangeEvent):void => {
    setFilepath(event.target.value);
  };

  const handleClick = ():void => {
    if (!filepath) return;
    downloadFile(token, filepath);
  };

  const renderItems = ():JSX.Element[] | undefined => data?.map((item) => <MenuItem key={item.path} value={item.path}>{item.name}</MenuItem>);

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <br />
          <CircularProgress color="secondary" />
        </Grid>
      </Grid>
    );
  }

  if (error && !loading) {
    const msg = `${error.name}: ${error.message}`;
    return (
      <Grid item xs={12}>
        <Alert severity="error">{msg}</Alert>
      </Grid>
    );
  }

  return (
    <div>
      <h4>DOWNLOAD A REPORT</h4>
      <FormControl variant="standard" sx={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Report</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filepath}
          label="Age"
          onChange={handleChange}
        >
          {renderItems()}
        </Select>
      </FormControl>
      <Button color="primary" variant="contained" sx={classes.downloadButton} onClick={handleClick}>DOWNLOAD</Button>
    </div>
  );
};

export default ReportDownload;
