/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState, useContext, ChangeEvent,
} from 'react';
import {
  Grid, Container, Button, MenuItem, InputLabel, FormControl, Alert, Snackbar,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OliAxios from 'api/util/OliAxios';
import UserContext from 'context/UserContext';
import { getConfig } from 'api/util/getConfig';
import useAxiosGet from 'hooks/useAxiosGet.hook';
import { useStyles } from './Dropzones.jss';
import {
  PreSignedURLResponse, DropzonesProps, Tag,
} from '../FileTransfer.types';
import analytics from '../../../Analytics';

const Dropzones: React.FunctionComponent<DropzonesProps> = (props) => {
  const {
    fileNo, getFileData, matterUuid,
  } = props;

  const classes = useStyles;
  const [tag, setTag] = useState('');
  const [description, setDescription] = useState<string | undefined>('');
  const API_ENDPOINT = '/fileTransfer/upload';
  const token = useContext(UserContext)?.token;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const url = '/artifactTags';

  const {
    data,
  } = useAxiosGet<Tag[]>(url, [url]);
  const renderItems = ():JSX.Element[] | undefined => data?.map((t) => (
    <MenuItem
      key={t.oliver_code}
      value={
          t.client_tags
      }
    >
      {`${t.oliver_code} ${t.description}`}
    </MenuItem>
  ));

  const handleChange = (event: SelectChangeEvent):void => {
    const clientTag = data?.find((element) => element.client_tags === event.target.value);
    setTag(event.target.value);
    setDescription(clientTag?.description);
  };

  const handleUpload = async (files:FileList, filenames:string[], uploadTag:string, retryCount = 0):Promise<void> => {
    if (!token) return;
    let retry = retryCount;
    try {
      if (files.length === 0) return;
      const response:PreSignedURLResponse = await OliAxios.get(API_ENDPOINT, {
        ...getConfig(token),
        params: {
          filenames, description, tags: uploadTag, file_no: fileNo, matter_uuid: matterUuid,
        },
      });

      const uploadUrls = response.data.uploadurls;
      const promises = [];
      for (let i = 0; i < uploadUrls.length; i++) {
        if (uploadUrls[i].key.includes(files[i].name)) {
          const options = {
            headers: {
              'Content-Type': files[i].type,
            },
          };

          analytics.track('file-transfer-upload', { file_description: description });

          promises.push(OliAxios.put(uploadUrls[i].uploadURL, files[i], options));
        }
      }
      await Promise.all(promises);
      setTimeout(() => { getFileData(); }, 2000);
    } catch (e) {
      if (retry < 2) {
        handleUpload(files, filenames, uploadTag, retry += 1);
      } else {
        throw e as Error;
      }
    }
  };

  async function handleFileChange(event: ChangeEvent<HTMLInputElement>):Promise<void> {
    const { files } = event.target;
    if (!files) return;
    const filenames:string[] = [];
    for (let i = 0; i < files.length; i++) {
      filenames.push(files[i].name);
    }
    await handleUpload(files, filenames, tag, 0);
  }

  const handleSuccess = (e:ChangeEvent<HTMLInputElement>):void => {
    setLoading(false);
    setOpenSuccess(true);
    e.target.value = '';
  };

  const handleError = (er:Error, e:ChangeEvent<HTMLInputElement>):void => {
    setLoading(false);
    setOpenFail(true);
    setError(er);
    e.target.value = '';
  };

  const onFileChange = (e:ChangeEvent<HTMLInputElement>):void => {
    setLoading(true);
    handleFileChange(e)
      .then(() => { handleSuccess(e); })
      .catch((er) => handleError(er, e));
  };

  const closeSnackBar = ():void => {
    setOpenSuccess(false);
    setOpenFail(false);
  };

  return (
    <>
      <Container style={{ paddingLeft: '0px' }}>
        <Grid container direction="row" justifyContent="space-evenly">
          <Grid item>
            <FormControl variant="standard" sx={classes.formControl}>
              <InputLabel id="tag-select-label">Tag</InputLabel>
              <Select
                labelId="tag-select-label"
                id="tag-select"
                value={tag}
                label="Tag"
                onChange={handleChange}
                fullWidth
              >
                {renderItems()}
              </Select>
            </FormControl>
          </Grid>
          <br />
          <Grid item>
            <span>
              <input
                accept=".pdf"
                id="file"
                type="file"
                multiple
                onChange={onFileChange}
                hidden
              />
              <label htmlFor="file">
                <Button disabled={loading} color="secondary" variant="contained" component="span" sx={classes.downloadButton}>
                  Upload
                </Button>
              </label>
              <Snackbar
                open={openSuccess}
                onClose={closeSnackBar}
              >
                <Alert onClose={closeSnackBar} severity="success" sx={classes.alert}>
                  File Upload Complete.
                </Alert>
              </Snackbar>
              <Snackbar
                open={openFail}
                onClose={closeSnackBar}
              >
                <Alert onClose={closeSnackBar} severity="error" sx={classes.alert}>
                  Something has gone wrong. (
                  {error?.message}
                  )
                </Alert>
              </Snackbar>
            </span>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dropzones;
