import React from 'react';
import {
  MenuItem, FormControl, InputLabel, Select,
} from '@mui/material';
import states from './states';
import { StateSelectProps } from './StateSelect.types';

const StateSelect: React.FunctionComponent<StateSelectProps> = (props) => {
  const {
    label, value, loading, required, error, handleChange,
  } = props;
  const renderItems = ():JSX.Element[] => states.map((state) => <MenuItem key={state.abbreviation} value={state.abbreviation}>{state.name}</MenuItem>);
  return (
    <>
      <FormControl variant="standard" sx={{ width: '100%' }}>
        <InputLabel id="select-label" error={error}>{label}</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={value || ''}
          label={label}
          fullWidth
          disabled={loading}
          onChange={handleChange}
          required={required}
          error={error}
        >
          {renderItems()}
        </Select>
      </FormControl>
    </>
  );
};

export default StateSelect;
