const useStyles = {
  header: {
    color: '#3e3842',
  },
  period: {
    color: '#ca304c',
  },
};

export { useStyles };
