import React, { useContext } from 'react';
import Header from 'components/Header/Header';
import UserContext from 'context/UserContext';

const SplashText: React.FunctionComponent = () => {
  const text:string = [
    'Oliver seamlessly automates secure data transfer, conversion, validation, and processing between parties. ',
    'This dashboard gives authorized users convenient access to files and provides visual business diagnostics at a glance.',
  ].join('');

  const name = useContext(UserContext)?.name;
  const headerText = `${name} Data Operations`;

  const renderSplashText = ():JSX.Element => {
    if (name) {
      return (
        <div>
          <Header text={headerText} period />
          <Header text={text} variant="p" />
        </div>
      );
    }
    return <></>;
  };
  return (
    <>
      { renderSplashText() }
    </>
  );
};

export default SplashText;
